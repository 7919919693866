import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { resetPasswordApi } from 'Customer/Apis/AuthApis/authApis'
import { FaEye } from "react-icons/fa";
import { FaRegEyeSlash } from "react-icons/fa";

function ResetPasswordPage() {
    const navigation = useNavigate()
    const userId = window.location.pathname.split("/")[2];
    const userToken = window.location.pathname.split("/")[3];
    const [showPsssword, setShowPassword] = useState(false)
    const [password, setPassword] = useState('')
    const [cPassword, setCPassword] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault()

        let data = {
            "password": password,
            "confirmPassword": cPassword,
        }
        resetPasswordApi(data,userId,userToken, navigation)
    }
 
    return (
        <>
            <section className="login_form_wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className=" col-lg-6 col-12 px-0">
                            <div className="login_img">
                                <img src="/img/login-bg.png" alt="img" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-12 d-flex justify-content-center align-items-center ">
                            <div className="login_form">
                                <div className="form">
                                    <div className="login_form_heading">
                                        <h2>Reset Password</h2>
                                        <span>Enter Your New Password</span>
                                    </div>
                                  
                                    <div className="tab-content">
                                        <form onSubmit={(e) => handleSubmit(e)} >
                                       
                                            <div className="mb-3 input-wrapper">
                                                <label htmlFor="examplePassword" className="form-label">New Password</label>
                                               <div className="position-relative">
                                               <input type={`${showPsssword?"text":"password"}`} className="form-control" value={password} onChange={(e)=>{setPassword(e.target.value)}} placeholder="Enter Password" />
                                                {
                                                    showPsssword 
                                                    ?
                                                    <FaRegEyeSlash className='show_password_icon' onClick={()=>setShowPassword(!showPsssword)} />
                                                    :
                                                    <FaEye className='show_password_icon' onClick={()=>setShowPassword(!showPsssword)}/>
                                                }
                                               </div>
                                            </div>

                                            <div className="mb-3 input-wrapper">
                                                <label htmlFor="examplePassword1" className="form-label">Confirm New Password</label>
                                                <div className="position-relative">
                                                <input  type={`${showPsssword?"text":"password"}`} className="form-control" value={cPassword} onChange={(e)=>{setCPassword(e.target.value)}} placeholder="Confirm Password" />
                                                {
                                                    showPsssword 
                                                    ?
                                                    <FaRegEyeSlash className='show_password_icon' onClick={()=>setShowPassword(!showPsssword)} />
                                                    :
                                                    <FaEye className='show_password_icon' onClick={()=>setShowPassword(!showPsssword)}/>
                                                }
                                                </div>
                                            </div>

                                            <div className="btn_wrapper login_form_btn mt-4">
                                                <button type="submit" className="btn">Reset</button>
                                            </div>

                                            <p>back To Login? <a href="/login">Login</a></p>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ResetPasswordPage
