import React from 'react'
import 'Customer/Styles/MainStyles/packageDetails/packageoverview.css'


function JungleSafariOverview({singlePackageData}) {
  return (
    <div>
      <section className="package_overview_main" id="overview">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 px-0">
            <div className="post__content" dangerouslySetInnerHTML={{__html: singlePackageData?.overview}}></div>
                
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default JungleSafariOverview
