import { useRef } from "react";
import Slider from "react-slick";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import "Customer/Styles/MainStyles/topdestination.css";
import { MdOutlineStarBorder } from "react-icons/md";
import { image_url } from "Utils/BaseURL";
import { useNavigate } from "react-router-dom";

function TopDestination({ allPackageList }) {
    const navigation =useNavigate()
  const data = [1, 2, 3, 4, 5, 6];
  let sliderRef = useRef(null);
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
  };

  const nextArrow = () => {
    sliderRef.slickNext();
  };
  const previousArrow = () => {
    sliderRef.slickPrev();
  };
  return (
    <>
      <section className="top_destinations_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12 position-relative">
              <div className="holiday_packages_wrapper_sec_heading">
                <h2>Popular Holiday Packages</h2>
                <div className="d-flex align-items-center">
                  <IoIosArrowBack onClick={() => previousArrow()} />
                  <IoIosArrowForward onClick={() => nextArrow()} />
                </div>
              </div>

              <div className="slider-container ">
                <Slider
                  ref={(slider) => {
                    sliderRef = slider;
                  }}
                  {...settings}
                >
                  {allPackageList?.map((item, i) => {
                    return (
                      <div key={i} className="card" onClick={()=>navigation(`/customer/package-details/${item?._id}`)}>
                        {item?.Images?.length > 0 ? (
                          <img
                            src={`${image_url}${item?.Images[0]?.src}`}
                            className="card-img-top"
                            alt="img"
                          />
                        ) : null}

                        <div className="card-body">
                          <div className="card-head">
                            <div>
                              <h5 className="card-title">
                                {item?.packageName}
                              </h5>
                              <h6 className="sub-heading">
                                {item?.packageTitle}
                              </h6>
                            </div>

                            {/* <div className="destination_offer">
                              <span>30% Off</span>
                            </div> */}
                          </div>
                          <p className="card-text">
                            {item?.packageShortDescription}
                          </p>
                          <div className="destination_card_footer">
                            <div className="rating">
                              4.0
                              <MdOutlineStarBorder />
                              <MdOutlineStarBorder />
                              <MdOutlineStarBorder />
                              <MdOutlineStarBorder />
                              <MdOutlineStarBorder />
                            </div>
                            <button className="">Book Now</button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TopDestination;
