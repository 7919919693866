import React, { useState, useEffect } from 'react'
import 'Customer/Styles/MainStyles/homesegment.css'
import { getAllSubServicesApi } from 'Customer/Apis/MainApis/mainApis'
import { image_url } from 'Utils/BaseURL'

function HomeSegment() {
    const [allSubServices, setAllSubServices] = useState([])

    useEffect(() => {
        getAllSubServicesApi(1, (data) => {
            setAllSubServices(data)
        })
    }, [])
//    console.log(allSubServices, 'allSubServicesallSubServicesallSubServices')
    return (
        <>
            <section className="segments_wrappers">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="home_segments_sec_heading">
                                <h2>Segments</h2>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="segments_cards_wrapper">
                                <div className="row">
                                    {
                                        allSubServices?.slice(0, 4)?.map((item, i) => {
                                            return <div key={i} className="col-md-3 col-sm-6 col-12">
                                                <div className="segment_card">
                                                   <a href={`${item?.slug}/${item?._id}`}>
                                                   <div className="segment_card_img">
                                                        <img src={`${image_url}${item?.image}`} alt="image" />
                                                    </div>
                                                    <div className="segment_card_name">
                                                        <p>{item?.subServiceName}</p>
                                                    </div>
                                                   </a>
                                                </div>
                                            </div>
                                        })
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeSegment
