import React from 'react'
import './../styles/mybookings.css'

function AllBookings() {
    return (
        <div>
            <div className="dashbord_content_wrapper">
                <div className="row">
                    <div className="col-12">
                        <div className="dashboard_main-upper_wrapper d-flex justify-content-between align-items-center">
                            <h1>Dashboard</h1>
                            <div className="profile_sec_wrapper d-flex align-items-center">
                                <div className="notification me-4">
                                    <a href=""> <img src="/img/notification.png" alt=""/></a>
                                </div>

                                <div className="profile_wrapper d-flex align-items-center">
                                    <div className="dashboard_profile_img">
                                        <img src="/img/dummy.png" alt="img"/>
                                    </div>
                                    <div className="dashboard_profile_name">
                                        Mr. Mehta
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="dashboard_content h-auto">
                            <div className="customer_booking_table">
                                <table className="table">
                                    <thead>
                                        <tr style={{borderBottom :"1px solid rgba(28, 27, 31, 0.5)"}}>
                                            <th scope="col">Clients Name</th>
                                            <th scope="col">Package Name</th>
                                            <th scope="col">Total Earnings</th>
                                            <th scope="col">Total Bookings </th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Duration</th>
                                            <th scope="col">Price</th>
                                            <th scope="col">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td scope="row">
                                                <div className="table_profile d-flex align-items-center">
                                                    <div className="table_profile_img">
                                                        <img src="/img/dummy.png" alt="img"/>
                                                    </div>

                                                    <div className="table_profile_name ms-2">
                                                        <p>Mr. Mehta</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className='pt-3' >Holiday Resorts</td>
                                            <td className='pt-3' >$ 4894</td>
                                            <td className='pt-3' >856</td>
                                            <td className='pt-3' >20 June 2024</td>
                                            <td className='pt-3' >15 Days</td>
                                            <td className='pt-3' >$985</td>
                                            <td> <button className="btn btn_confirm"><a href="#">Confirmed</a></button> </td>
                                        </tr>

                                   

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllBookings
