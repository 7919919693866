import React from 'react'

function MyBookingPage() {
  return (
    <div>
       <h2>My Booking Page</h2>
    </div>
  )
}

export default MyBookingPage
