import React from 'react'
import 'Customer/Styles/MainStyles/resortDetails/resortoverview.css'


function ResortOverview({hotelOverview}) {
  return (
    <div>
         <section className="resort_overview_main" id="overview">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 px-0">
         
             <div className="post__content" dangerouslySetInnerHTML={{__html: hotelOverview}}></div>
                
          </div>
        </div>
      </div>
    </section>
    </div>
  )
}

export default ResortOverview
