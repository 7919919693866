import React from 'react'
import 'Customer/Styles/MainStyles/footer.css'
import { FaFacebookF, FaXTwitter   } from "react-icons/fa6";
import { FaInstagram, FaLinkedinIn  } from "react-icons/fa";

function Footer() {
  return (
    <div>
      <section className="footer_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-6 col-12">
              <div className="quick_links">
                <div className="footer_sec_heading">
                  <h4>About Maldives Holidays</h4>
                </div>
                <ul className="p-0" >
                  <li><a href="#">About us</a></li>
                  <li><a href="#">Press</a></li>
                  <li><a href="#">Resources and Policies</a></li>
                  <li><a href="#">Partners</a></li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6 col-12">
              <div className="quick_links">
                <div className="footer_sec_heading">
                  <h4>Explore</h4>
                </div>
                <ul className="p-0" >
                  <li><a href="#">Luxury Resorts</a></li>
                  <li><a href="#">Covid-19 Updates</a></li>
                  <li><a href="#">Maldives Culture</a></li>
                  <li><a href="#">Travel Guide</a></li>
                  <li><a href="#">Maldives Visa</a></li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6 col-12">
              <div className="quick_links">
                <div className="footer_sec_heading">
                  <h4>Do Business With Us</h4>
                </div>
                <ul className="p-0" >
                  <li><a href="#">Accommodation Owners</a></li>
                  <li><a href="#">Business Advantage</a></li>
                  <li><a href="#">Advertise with Us</a></li>
                  <li><a href="#">Affiliates & Partners</a></li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6 col-12">
              <div className="footer_social_links_wrappers">
                <div className="footer_sec_heading">
                  <h4>Follow Us</h4>
                </div>

                <div className="footer_social_links">
                  <ul className="p-0" >
                    <li className="social_link"><a href="#"><FaFacebookF/></a></li>
                    <li className="social_link"><a href="#"><FaInstagram /></a></li>
                    <li className="social_link"><a href="#"><FaXTwitter /></a></li>
                    <li className="social_link"><a href="#"><FaLinkedinIn /></a></li>
                  </ul>
                </div>

                <div className="footer_logo">
                  <div className="footer_logo_img">
                    <img src="/img/footer-logo1.png" alt="" />
                  </div>
                  <div className="footer_logo_img">
                    <img src="/img/footer-logo2.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
    </div>
  )
}

export default Footer
