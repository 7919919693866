import React,{useEffect, useState} from 'react'
import 'Customer/Styles/MainStyles/resortDetails/similiarresort.css'
import { getAllHotelsApi } from 'Customer/Apis/MainApis/mainApis'
import { image_url } from 'Utils/BaseURL'


function ResortSimiliar() {
  const [allHotelsListing, setAllHotelsListing] = useState([])
  const [page, setPage] = useState()

  useEffect(()=>{
      getAllHotels()
  },[])

  const getAllHotels = async()=>{
    getAllHotelsApi(page,(data)=>{
      setAllHotelsListing(data)
    })
  }


  return (
    <div>
      <section className="similar_property_wrapper" id="similar_properties">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 px-0">
              <div className="similar_property">
                <div className="row">
                  <div className="col-12">
                    <div className="detail_tabs_heading">
                      <h2>Similar Resorts</h2>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {
                    allHotelsListing?.slice(0,4).map((item,i)=>{
                      return  <div key={i} className="col-3">
                      <div className="similar_property_cards">
                        <div className="card">
                          <img src={`${image_url}${item?.roomImage[0]?.src}`} className="card-img-top" alt="img" />
                          <div className="card-body">
                            <div className="card-head d-flex justify-content-between">
                              <h5 className="card-title">{item?.hotelName}</h5>
                              <ul className="d-flex">
                                <li>4.0</li>
                                <li><img src="/img/colored-star.png" alt="" /></li>
                                <li><img src="/img/colored-star.png" alt="" /></li>
                                <li><img src="/img/colored-star.png" alt="" /></li>
                                <li><img src="/img/colored-star.png" alt="" /></li>
                                <li><img src="/img/simple-star.png" alt="" /></li>
                              </ul>
                            </div>
                            <span className="sub-title">{item?.hotelTitle}</span>
                            <p className="card-text">Lorem Ipsum is simply dummy text of. Lorem Ipsum
                              is simply dummy text of.Lorem Ipsum is simply dummy text of. Lorem Ipsum is
                              simply dummy text of. Lorem Ipsum is simply dummy text of. Lorem Ipsum is
                              simply dummy text of.</p>
                            <div className="card_btn d-flex justify-content-between">
                              <a href="#" className="btn">View Details</a>
                              <a href="#" className="btn" style={{backgroundColor: "#11ABE7", color:" #ffffff", border: "none"}}>Book Now</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    })
                  }
                 

                  {/* <div className="col-3">
                    <div className="similar_property_cards">
                      <div className="card">
                        <img src="/img/destination.png" className="card-img-top" alt="img" />
                        <div className="card-body">
                          <div className="card-head d-flex justify-content-between">
                            <h5 className="card-title">Resort & Spa</h5>
                            <ul className="d-flex">
                              <li>4.0</li>
                              <li><img src="/img/colored-star.png" alt="" /></li>
                              <li><img src="/img/colored-star.png" alt="" /></li>
                              <li><img src="/img/colored-star.png" alt="" /></li>
                              <li><img src="/img/colored-star.png" alt="" /></li>
                              <li><img src="/img/simple-star.png" alt="" /></li>
                            </ul>
                          </div>
                          <span className="sub-title">South Ari Atoll</span>
                          <p className="card-text">Lorem Ipsum is simply dummy text of. Lorem Ipsum
                            is simply dummy text of.Lorem Ipsum is simply dummy text of. Lorem Ipsum is
                            simply dummy text of. Lorem Ipsum is simply dummy text of. Lorem Ipsum is
                            simply dummy text of.</p>
                          <div className="card_btn d-flex justify-content-between">
                            <a href="#" className="btn">View Details</a>
                            <a href="#" className="btn" style={{backgroundColor:"#11ABE7", color: "#ffffff", border: "none"}}>Book Now</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-3">
                    <div className="similar_property_cards">
                      <div className="card">
                        <img src="/img/destination.png" className="card-img-top" alt="img" />
                        <div className="card-body">
                          <div className="card-head d-flex justify-content-between">
                            <h5 className="card-title">Resort & Spa</h5>
                            <ul className="d-flex">
                              <li>4.0</li>
                              <li><img src="/img/colored-star.png" alt="" /></li>
                              <li><img src="/img/colored-star.png" alt="" /></li>
                              <li><img src="/img/colored-star.png" alt="" /></li>
                              <li><img src="/img/colored-star.png" alt="" /></li>
                              <li><img src="/img/simple-star.png" alt="" /></li>
                            </ul>
                          </div>
                          <span className="sub-title">South Ari Atoll</span>
                          <p className="card-text">Lorem Ipsum is simply dummy text of. Lorem Ipsum
                            is simply dummy text of.Lorem Ipsum is simply dummy text of. Lorem Ipsum is
                            simply dummy text of. Lorem Ipsum is simply dummy text of. Lorem Ipsum is
                            simply dummy text of.</p>
                          <div className="card_btn d-flex justify-content-between">
                            <a href="#" className="btn">View Details</a>
                            <a href="#" className="btn" style={{backgroundColor:"#11ABE7", color: "#ffffff", border: "none"}}>Book Now</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-3">
                    <div className="similar_property_cards">
                      <div className="card">
                        <img src="/img/destination.png" className="card-img-top" alt="img" />
                        <div className="card-body">
                          <div className="card-head d-flex justify-content-between">
                            <h5 className="card-title">Resort & Spa</h5>
                            <ul className="d-flex">
                              <li>4.0</li>
                              <li><img src="/img/colored-star.png" alt="" /></li>
                              <li><img src="/img/colored-star.png" alt="" /></li>
                              <li><img src="/img/colored-star.png" alt="" /></li>
                              <li><img src="/img/colored-star.png" alt="" /></li>
                              <li><img src="/img/simple-star.png" alt="" /></li>
                            </ul>
                          </div>
                          <span className="sub-title">South Ari Atoll</span>
                          <p className="card-text">Lorem Ipsum is simply dummy text of. Lorem Ipsum
                            is simply dummy text of.Lorem Ipsum is simply dummy text of. Lorem Ipsum is
                            simply dummy text of. Lorem Ipsum is simply dummy text of. Lorem Ipsum is
                            simply dummy text of.</p>
                          <div className="card_btn d-flex justify-content-between">
                            <a href="#" className="btn">View Details</a>
                            <a href="#" className="btn" style={{backgrounColor: "#11ABE7", color: "#ffffff", border: "none"}}>Book Now</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ResortSimiliar
