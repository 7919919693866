import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "Customer/Styles/MainStyles/packageDetails/packageaddonmodal.css";

function PackageAddOnModal({
  openPackageAddOnModal,
  setOpenPackageAddOnModal,
  allAddOns,
  selectedAddOnServices,
  handleSelectAddonService,
}) {

  return (
    <div>
      <Modal
        show={openPackageAddOnModal}
        onHide={() => {
          setOpenPackageAddOnModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Choose Services</Modal.Title>
        </Modal.Header>
        <Modal.Body>
     
          {allAddOns?.map((item, i) => (
            <div
              key={i}
              className="mb-0 border p-3 row d-flex justify-content-between align-items-center"
            >
          
              <div className="col-8">
                <h5 className="fw-bold">{item?.addOnName}</h5>
                <p className="m-0 p-0">{item?.description}</p>
                <p className="m-0 p-0" style={{ color: "#ff6600" }}>
                  {item?.price} $
                </p>
              </div>

              <div className="col-4">
                {selectedAddOnServices?.includes(item._id) ? (
                  <button
                    type="submit"
                    className={`${"selected_button_package_Add_on"}`}
                    onClick={() => {
                      handleSelectAddonService(item?._id, item?.price);
                      setOpenPackageAddOnModal(item?._id);
                    }}
                  >
                    Selected
                  </button>
                ) : (
                  <button
                    type="submit"
                    className={`${"select_button_package_Add_on"}`}
                    onClick={() => {
                      handleSelectAddonService(item?._id, item?.price);
                      setOpenPackageAddOnModal(item?._id);
                    }}
                  >
                    Select
                  </button>
                )}
              </div>
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setOpenPackageAddOnModal(false);
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setOpenPackageAddOnModal(false);
            }}
          >
            Select & Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default PackageAddOnModal;
