import React,{useState, useEffect} from 'react'
import ResortFilters from 'Customer/Layouts/ResortListingLayouts/ResortFilters'
import ResortListingLayout from 'Customer/Layouts/ResortListingLayouts/ResortListingLayout'
import HomeBanner from 'Customer/Layouts/HomeLayouts/HomeBanner'
import { getHotelsSearchHomeApi } from 'Customer/Apis/MainApis/mainApis'

function SearchHomePage() {
  const [allHotelsListing, setAllHotelsListing] = useState([])
  const [page, setPage] = useState(1)

  useEffect(()=>{
      getAllHotels()
  },[])

  const getAllHotels = async()=>{
    let lat = window.location.pathname.split("/")[2]
    let lng = window.location.pathname.split("/")[3]
    let startingDate = localStorage.getItem('starting_date')
    let endingDate = localStorage.getItem('ending_date')
    let body={
            "lat":lat,
            "lng": lng,
            "startingDate":startingDate,
            "endingDate": endingDate,
            "totalAdult": "",
            "totalChild": "",
            "page":page
          }
    
    getHotelsSearchHomeApi(page,(data)=>{
      setAllHotelsListing(data)
    })
  }


  return (
    <div>
      <HomeBanner />

      <section className="mx-5 px-5 mt-4">
        <div className="container-fluid">
          <div className="row">
            < div className="col-12">
              <div className="">
                <div className="row">
                  <div className="col-2">
                    <ResortFilters />
                  </div>
                  <div className='col-10' >
                    {
                      allHotelsListing?.length===0
                      ?
                      <div className='d-flex align-items-center justify-content-center' >
                        <h4>No Hotels Found</h4>
                        </div>
                      :
                      <ResortListingLayout allHotelsListing={allHotelsListing} />
                    }
                 
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default SearchHomePage
