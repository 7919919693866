import React from "react";
import "Customer/Styles/MainStyles/resortDetails/resortamenities.css";

function ResortAmenities({ singleHotelData }) {
  return (
    <div>
      <section className="amenities_wrapper" id="amenities">
        <div className="contanier-fluid">
          <div className="row">
            <div className="col-12">
              <div className="details_tabs_content_wrapper">
                <div className="detail_tabs_heading">
                  <h2>Amenities</h2>
                </div>

                <div className="details_tab_content d-flex">
                  <div className="post__content" dangerouslySetInnerHTML={{__html: singleHotelData?.amenitites}}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ResortAmenities;
