import React from 'react'
import 'Customer/Styles/MainStyles/packageDetails/packagecancellationpolicy.css'


function JungleSafariCancellationPolicy({singlePackageData}) {
  return (
    <div>
        <section className="package_cancellation_policy_wrapper" id="cancellation_policy">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 px-0">
            <div className="post__content" dangerouslySetInnerHTML={{__html: singlePackageData?.cancelPolicy}}></div>
                
            </div>
          </div>
        </div>
     </section>
    </div>
  )
}

export default JungleSafariCancellationPolicy
