import React, { useState, useEffect } from "react";
import "Customer/Styles/MainStyles/packageDetails/packagemaindetails.css";
import PackageAddOnModal from "Customer/Modals/PackageAddOnModal";
import AllCouponModal from "Customer/Modals/AllCouponModal";
import { getAllAddOnServicesForPackage, getAllCoupons } from "Customer/Apis/MainApis/mainApis";

function PackageMainDetails({ singlePackageData }) {
  const [openPackageAddOnModal, setOpenPackageAddOnModal] = useState(false);
  const [openAllCouponModal, setOpenAllCouponModal] = useState(false);
  const [allAddOns, setAllAddOns] = useState([]);
  const [allCoupons, setAllCoupons] = useState([]);

  const [addOnPrice, setAddOnPrice] = useState([]);
  const [selectedCouponId, setSelectedCouponId] = useState('');

  const [selectedAddOnServices] = useState([]);
  const [selectedCouponsPrice, setSelectedCouponsPrice] = useState(0);


  useEffect(() => {
    getData();
  }, [openPackageAddOnModal, selectedAddOnServices]);

  const getData = async () => {
    if (singlePackageData?.addOnsServices?.length > 0) {
      getAllAddOnServicesForPackage(
        singlePackageData?.addOnsServices,
        (data) => {
          setAllAddOns(data);
        }
      );
    }

    if (singlePackageData?.coupons?.length > 0) {
      getAllCoupons(
        singlePackageData?.coupons,
        (data) => {
          setAllCoupons(data);
        }
      );
    }
  };

  useEffect(() => {
    setAddOnPrice(addOnPrice);
  }, [addOnPrice]);

  let totalAddOnPrice = 0

  for (let i = 0; i < addOnPrice.length; i++) {
    totalAddOnPrice += addOnPrice[i];
  }

  const handleSelectAddonService = async (id, price) => {
    if (selectedAddOnServices?.includes(id)) {
      let findIndexAutoReply = selectedAddOnServices?.findIndex(
        (val) => val === id
      );
      selectedAddOnServices?.splice(findIndexAutoReply, 1);
      addOnPrice.splice(findIndexAutoReply, 1);
    } else {
      selectedAddOnServices?.push(id);
      addOnPrice?.push(price);
    }
  }

  const handleSelectCoupon = async (id, price) => {
    if (selectedCouponId === id) {
      setSelectedCouponId('')
      setSelectedCouponsPrice(0)
    } else {
      setSelectedCouponId(id);
      setSelectedCouponsPrice(price)
    }
  }

  return (
    <div>
      <AllCouponModal
        openAllCouponModal={openAllCouponModal}
        setOpenAllCouponModal={setOpenAllCouponModal}
        allCoupons={allCoupons}
        selectedCouponId={selectedCouponId}
        handleSelectCoupon={handleSelectCoupon}
      />
      <PackageAddOnModal
        openPackageAddOnModal={openPackageAddOnModal}
        setOpenPackageAddOnModal={setOpenPackageAddOnModal}
        allAddOns={allAddOns}
        selectedAddOnServices={selectedAddOnServices}
        handleSelectAddonService={handleSelectAddonService}
        selectedCouponsPrice={selectedCouponsPrice}
      />

      <div className="pakcgae_details_main">
        <h2>{singlePackageData?.packageName}</h2>
        <h4>{singlePackageData?.packageTitle}</h4>
        <p>{singlePackageData?.packageShortDescription}</p>
        {singlePackageData?.packageType?.map((item, i) => {
          return <h6 key={i}>{item?.label}</h6>;
        })}

        <div className="package_description my-3">
          <h4>Package Description</h4>
          <div
            className="post__content"
            dangerouslySetInnerHTML={{ __html: singlePackageData?.description }}
          ></div>
        </div>
        <div className="package_address ">
          <h4>Address</h4>
          <p>{singlePackageData?.address}</p>
        </div>

        <div className="package_details_main_add_ons">
          <p onClick={() => setOpenPackageAddOnModal(true)}>Add On Services</p>
        </div>

        <div className="my-3 row d-flex align-items-center justify-content-between">
          <div className="col-4 d-flex align-items-center  package_details_main_coupons_and_addons">
            <input
              type="text"
              placeholder="Apply Coupon"
              className="form-control w-75"
            />
            <button className="mx-2 package_details_apply_coupon_button">
              Apply
            </button>
            <p className="w-100 m-0 p-0" onClick={()=>setOpenAllCouponModal(true)} >View All Coupons</p>
          </div>
          <div className="col-4 d-flex align-items-center package_details_main_price">
            <h4> ${singlePackageData?.price + totalAddOnPrice + selectedCouponsPrice}</h4>
            <h5>$ {singlePackageData?.discountPrice }</h5>
          </div>
        </div>

        <div>
          <button className="package_book_now_button">Book Now</button>
        </div>
      </div>
    </div>
  );
}

export default PackageMainDetails;
