import React from 'react'
import './../styles/editprofile.css'

function EditProfileMain() {
    return (
        <div>
            <div className="dashbord_content_wrapper">
                <div className="row">
                    <div className="col-12">
                        <div className="dashboard_main-upper_wrapper d-flex justify-content-between align-items-center">
                            <h1>Dashboard</h1>
                            <div className="profile_sec_wrapper d-flex align-items-center">
                                <div className="notification me-4">
                                    <a href="#"> <img src="/img/notification.png" alt="" /></a>
                                </div>

                                <div className="profile_wrapper d-flex align-items-center">
                                    <div className="dashboard_profile_img">
                                        <img src="/img/dummy.png" alt="" />
                                    </div>
                                    <div className="dashboard_profile_name">
                                        Mr. Mehta
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="dashboard_content">
                            <div className="edit_profile_form">
                                <form>
                                    <div className="row">
                                        <div className="col">
                                            <label htmlFor="exampleFirstName" className="form-label">First Name</label>
                                            <input type="text" className="form-control" id="exampleFirstName" />
                                        </div>
                                        <div className="col">
                                            <label htmlFor="exampleLastName" className="form-label">Last Name</label>
                                            <input type="text" className="form-control" id="exampleLastName" />
                                        </div>
                                        <div className="col">
                                            <label htmlFor="exampleEmailId" className="form-label">Email address</label>
                                            <input type="email" className="form-control" id="exampleEmailId" />
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col">
                                            <label htmlFor="exampleNum" className="form-label">Phone No.</label>
                                            <input type="email" className="form-control" id="exampleNum" />
                                        </div>
                                        <div className="col">
                                            <label htmlFor="exampleComName" className="form-label">Company Name</label>
                                            <input type="email" className="form-control" id="exampleComName" />
                                        </div>
                                        <div className="col">
                                            <label htmlFor="exampleComAdd" className="form-label">Company Address</label>
                                            <input type="email" className="form-control" id="exampleComAdd" />
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col">
                                            <label htmlFor="exampleGst" className="form-label">GST No.</label>
                                            <input type="email" className="form-control" id="exampleGst" />
                                        </div>
                                        <div className="col">
                                            <label htmlFor="examplePinCode" className="form-label">Pin Code</label>
                                            <input type="email" className="form-control" id="examplePinCode" />
                                        </div>
                                        <div className="col">
                                            <label htmlFor="exampleCountry" className="form-label">Country</label>
                                            <input type="email" className="form-control" id="exampleCountry" />
                                        </div>
                                    </div>

                                    <div className="row mt-4 mx-1 d-flex">
                                        <button className="save_button">Save</button>
                                        <button className="cancel_button" style={{backgroundColor: "#ffffff"}}>Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditProfileMain
