import React from 'react'

function AllSegmentsListing() {
  return (
    <div>
       <h2> segment listing</h2>
    </div>
  )
}

export default AllSegmentsListing
