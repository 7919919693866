import React from 'react'
import './../styles/mainDashboard.css'

function MainDashboard() {
    return (
        <div>
            <div className="dashbord_content_wrapper">
                <div className="row">
                    <div className="col-12">
                        <div className="dashboard_main-upper_wrapper d-flex justify-content-between align-items-center">
                            <h1>Dashboard</h1>
                            <div className="profile_sec_wrapper d-flex align-items-center">
                                <div className="notification me-4">
                                    <a href=""> <img src="/img/notification.png" alt="" /></a>
                                </div>

                                <div className="profile_wrapper d-flex align-items-center">
                                    <div className="dashboard_profile_img">
                                        <img src="/img/dummy.png" alt="img" />
                                    </div>
                                    <div className="profile_name">
                                        Mr. Mehta
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="dashboard_content p-0" style={{background: "none", boxShadow: "none"}}>
                            <div className="dashboard_screen_hero_banner">
                                <div className="dashboard_hero_content">
                                    <h1>Welcome to Maldives Holidays</h1>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                                        the industry's standard dummy text ever since the 1500s, when an unknown printer.</p>
                                </div>
                            </div>



                            <div className="dashboard_welcome_agents_cards">
                                <div className="dashboard_heading">
                                    <h2>Welcome Agent</h2>
                                </div>

                                <div className="welcome_upper_cards_wrapper">
                                    <div className="row">
                                        <div className="col-4">
                                            <div className="welcome_upper_cards d-flex" style={{background:"linear-gradient(270deg, #11ABE7 -0.14%, #095F81 97.08%)"}}>
                                                <div className="welcome_upper_img">
                                                    <img src="/img/visitors.png" alt="img" />
                                                </div>
                                                <div className="welcome_upper_content">
                                                    <span>Visitors</span>
                                                    <h2>50</h2>
                                                    <p>Yesterday : 09</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-4">
                                            <div className="welcome_upper_cards d-flex mx-auto" style={{background:"linear-gradient(270deg, #72CF02 0.09%, #3A6901 99.91%)"}}>
                                                <div className="welcome_upper_img">
                                                    <img src="/img/bookings.png" alt="img" />
                                                </div>
                                                <div className="welcome_upper_content">
                                                    <span>Bookings</span>
                                                    <h2>50</h2>
                                                    <p>Yesterday : 09</p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-4">
                                            <div className="welcome_upper_cards d-flex ms-auto" style={{background:"linear-gradient(270deg, #FE8478 2.75%, #E53F2E 100.14%)"}}>
                                                <div className="welcome_upper_img">
                                                    <img src="/img/cancle.png" alt="img" />
                                                </div>
                                                <div className="welcome_upper_content">
                                                    <span>Cancellations</span>
                                                    <h2>50</h2>
                                                    <p>Yesterday : 09</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-8">
                                            <div className="welcome_lower_cards1 d-flex" style={{background: "linear-gradient(90deg, #FFD971 0%, #FFD560 100%)"}} >
                                                <div className="lower_card_inner d-flex justify-content-center align-items-center flex-column">
                                                    <div className="welcome_lower_heading">
                                                        <h4>Online Sale</h4>
                                                    </div>
                                                    <div className="welcome_lower_content">
                                                        <p>$ 156165.00</p>
                                                    </div>
                                                </div>

                                                <div className="lower_card_inner d-flex justify-content-center align-items-center flex-column">
                                                    <div className="welcome_lower_heading">
                                                        <h4 style={{color: "#72CF02"}}>Total Profit</h4>
                                                    </div>
                                                    <div className="welcome_lower_content">
                                                        <p>$ 156165.00</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-4">
                                            <div className="welcome_lower_cards2 ms-auto d-flex" style={{background: "linear-gradient(90deg, #FF6600 0%, #FFA76C 100%)"}}>
                                                <div className="lower_card2_inner">
                                                    <div className="lower_card2_img">
                                                        <img src="/img/check-in.png" alt="img" />
                                                    </div>
                                                    <h2>785</h2>
                                                    <p>Check In</p>
                                                </div>

                                                <div className="lower_card2_inner">
                                                    <div className="lower_card2_img">
                                                        <img src="/img/check-out.png" alt="img" />
                                                    </div>
                                                    <h2>655</h2>
                                                    <p>Check Out</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainDashboard
