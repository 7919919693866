import React from 'react'
import 'Customer/Styles/MainStyles/resortDetails/resortpolicy.css'


function ResortPolicy({resortPolicy}) {
  return (
    <div>
      <section className="package_policy_wrapper" id="resort_policy">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 px-0">
           
               <div className="post__content" dangerouslySetInnerHTML={{__html: resortPolicy}}></div>
               
              </div>
            </div>
          </div>
     </section>
    </div>
  )
}

export default ResortPolicy
