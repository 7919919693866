import { useState } from "react";
import "Customer/Styles/MainStyles/packageDetails/packagephotogallery.css";
import { image_url } from "Utils/BaseURL";

function PackagePhotoGallery({ singlePackageData, allOfferLists }) {
  const data = [1, 2, 3];
  return (
    <div>
      <section className="package_detail_page_wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="package_detail_gallery_wrapper">
                <div className="row">
                  <div className="col-xl-8 col-12">
                    <h4>Photo Gallery</h4>
                    {
                      singlePackageData?.Images?.length>0
                      ?
                      <div className="main_gallery">
                      <div className="row">
                        <div className="col-8">
                          <div className="gallery_display_img">
                            <img
                              src={`${image_url}${singlePackageData?.Images[0]?.src}`}
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="side_gallery">
                            <div className="side_gallery_img">
                              <img
                                src={`${image_url}${singlePackageData?.Images[1]?.src}`}
                                alt="img"
                              />
                            </div>
                            <div className="side_gallery_img">
                              <div className="card text-white">
                                <img
                                  src={`${image_url}${singlePackageData?.Images[2]?.src}`}
                                  alt="img"
                                />
                                <div className="card-img-overlay">
                                  <button className="btn">
                                    <a href="#">View All</a>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    :
                    null
                    }
                   
                  </div>

                  <div className="col-xl-4 col-12">
                    <div className="detail_flatoff_wrapper">
                      <div className="row">
                        <div className="col-xl-12 col-lg-4 col-12">
                          <h4>Offers</h4>
                          {allOfferLists?.map((item, i) => {
                            return (
                              <div
                                key={i}
                                className="package_flatoff_cards mb-2"
                              >
                                <div className="row">
                                  <div className="col-4">
                                    <div className="flatoff_img">
                                      <img
                                        src={`${image_url}${item?.image}`}
                                        alt="img"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-8">
                                    <div className="flatoff_content_wrapper">
                                      <span>T&C Apply</span>
                                      <h2>{item?.offerName}</h2>
                                      <h4>{item?.offerType}</h4>
                                      <p>
                                        {item?.description}
                                      </p>
                                      <h2>Offer Starting Price : {item?.price} $</h2>
                                      <p>{item?.discountInPercentage}% Off</p>
                                      <div className="flatoff_btn">
                                        <button className="btn">
                                          <a href="#">Book Now</a>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PackagePhotoGallery;
