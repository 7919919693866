import React from 'react'
import 'Customer/Styles/MainStyles/packageDetails/packagetabs.css'


function PackagesTabs({selectedTab, setSelectedTab}) {
    return (
        <div>
            <section className="detail_navs_wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="resort_tabs_navbar">
                                <ul className="nav">
                                    <li className={`nav-item ${selectedTab==='1'?"active":""}`} onClick={()=>setSelectedTab('1')} >
                                       Overview
                                    </li>
                                    <li className={`nav-item ${selectedTab==='2'?"active":""}`} onClick={()=>setSelectedTab('2')} >
                                       Amenities
                                    </li>
                                    <li className={`nav-item ${selectedTab==='3'?"active":""}`} onClick={()=>setSelectedTab('3')} >
                                        Location
                                    </li>
                                    <li className={`nav-item ${selectedTab==='4'?"active":""}`} onClick={()=>setSelectedTab('4')} >
                                       Guest Reviews
                                    </li>
                                    <li className={`nav-item ${selectedTab==='5'?"active":""}`} onClick={()=>setSelectedTab('5')} >
                                      Cancellation Policy
                                    </li>
                                    <li className={`nav-item ${selectedTab==='6'?"active":""}`} onClick={()=>setSelectedTab('6')} >
                                       Resort Policy
                                    </li>
                                    <li className={`nav-item ${selectedTab==='7'?"active":""}`} onClick={()=>setSelectedTab('7')} >
                                       Similar Resorts
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default PackagesTabs


