import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { signupApi } from 'Customer/Apis/AuthApis/authApis'
import { FaEye } from "react-icons/fa";
import { FaRegEyeSlash } from "react-icons/fa";

function SignUpPage() {
    const navigation = useNavigate()
    const [showPsssword, setShowPassword] = useState(false)

    const [isLoginAs, setIsLoginAs] = useState('agent')

    const [email, setEmail] = useState('')
    const [fullName, setFullName] = useState('')
    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('')
    const [cPassword, setCPassword] = useState('')

    const handleSignup = async (e) => {
        e.preventDefault()

        let data = {
            "email": email,
            "password": password,
            "confirmPassword": cPassword,
            "role": isLoginAs,
            "phone": phone,
            "fullName": fullName
        }
        signupApi(data, navigation)
    }
    return (
        <>
            <section className="login_form_wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className=" col-lg-6 col-12 px-0">
                            <div className="login_img">
                                <img src="/img/login-bg.png" alt="img" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-12 d-flex justify-content-center align-items-center ">
                            <div className="login_form">
                                <div className="form">
                                    <div className="login_form_heading">
                                        <h2>Welcome</h2>
                                        <span>Sign in to continue</span>
                                    </div>
                                    <ul className="tab-group">
                                        <li className={`tab ${isLoginAs === 'agent' ? "active" : ""}`} onClick={() => setIsLoginAs('agent')} >Agent</li>
                                        <li className={`tab ${isLoginAs === 'customer' ? "active" : ""} m-0`} onClick={() => setIsLoginAs('customer')} >Customer</li>
                                    </ul>
                                    <div className="tab-content">
                                        <form onSubmit={(e) => handleSignup(e)} >
                                            <div className="mb-3 input-wrapper">
                                                <label htmlFor="exampleName" className="form-label">Full Name</label>
                                                <input type="text" className="form-control" value={fullName} onChange={(e)=>{setFullName(e.target.value)}} placeholder="Enter Your Name" />
                                            </div>

                                            <div className="mb-3 input-wrapper">
                                                <label htmlFor="examplePhno" className="form-label">Phone No.</label>
                                                <input type="number" className="form-control" value={phone} onChange={(e)=>{setPhone(e.target.value)}} placeholder="Enter Phone Number" />
                                            </div>

                                            <div className="mb-3 input-wrapper">
                                                <label htmlFor="exampleInputEmail1" className="form-label">Email Id</label>
                                                <input type="email" className="form-control" value={email} onChange={(e)=>{setEmail(e.target.value)}} placeholder="Enter Your Email" />
                                            </div>

                                            <div className="mb-3 input-wrapper">
                                                <label htmlFor="examplePassword" className="form-label">Password</label>
                                               <div className="position-relative">
                                               <input type={`${showPsssword?"text":"password"}`} className="form-control" value={password} onChange={(e)=>{setPassword(e.target.value)}} placeholder="Enter Password" />
                                                {
                                                    showPsssword 
                                                    ?
                                                    <FaRegEyeSlash className='show_password_icon' onClick={()=>setShowPassword(!showPsssword)} />
                                                    :
                                                    <FaEye className='show_password_icon' onClick={()=>setShowPassword(!showPsssword)}/>
                                                }
                                               </div>
                                            </div>

                                            <div className="mb-3 input-wrapper">
                                                <label htmlFor="examplePassword1" className="form-label">Confirm Password</label>
                                                <div className="position-relative">
                                                <input  type={`${showPsssword?"text":"password"}`} className="form-control" value={cPassword} onChange={(e)=>{setCPassword(e.target.value)}} placeholder="Confirm Password" />
                                                {
                                                    showPsssword 
                                                    ?
                                                    <FaRegEyeSlash className='show_password_icon' onClick={()=>setShowPassword(!showPsssword)} />
                                                    :
                                                    <FaEye className='show_password_icon' onClick={()=>setShowPassword(!showPsssword)}/>
                                                }
                                                </div>
                                            </div>

                                            <div className="btn_wrapper login_form_btn mt-4">
                                                <button type="submit" className="btn">Sign up</button>
                                            </div>

                                            <p>Already have an account? <a href="/login">Sign in</a></p>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SignUpPage
