import React from 'react'
import 'Customer/Styles/MainStyles/travelwithus.css'


function TravelWithUs() {
  return (
    <>
          <section className="travels_wrapper">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="travels_inner_wrapper">
             <div className="row">
              <div className="col-lg-11 col-xxl-7">
                <div className="travel_heading">
                  <h2>Why Travel With Us?</h2>
                  <span>Hotels and Homestays with our Check-
                         in to a Break Sale!
                  </span>
                 </div>
  
                 <div className="travel-list d-flex flex-column flex-lg-row justify-content-between">

                   <ul>
                    <li><i className="fa-solid fa-circle-check me-2"></i> Lorem Ipsum is simply dummy text of.</li>
                    <li> <i className="fa-solid fa-circle-check me-2"></i>Lorem Ipsum is simply dummy text of.</li>
                    <li> <i className="fa-solid fa-circle-check me-2"></i>Lorem Ipsum is simply dummy text of.</li>
                   </ul>

                   <ul className="mt-0">
                    <li> <i className="fa-solid fa-circle-check me-2"></i>Lorem Ipsum is simply dummy text of.</li>
                    <li> <i className="fa-solid fa-circle-check me-2"></i>Lorem Ipsum is simply dummy text of.</li>
                    <li> <i className="fa-solid fa-circle-check me-2"></i>Lorem Ipsum is simply dummy text of.</li>
                   </ul>

                 </div>

                 <div className="travel_btn">
                   <button className="">Find Out More!</button>
                 </div>
                 
              </div>
             </div>
            </div>
          </div>
        </div>
      </div>
     </section>
    </>
  )
}

export default TravelWithUs
