import React,{useState} from 'react'
import 'Customer/Styles/AuthStyles/Login.css'
import { loginApi } from 'Customer/Apis/AuthApis/authApis'
import { useNavigate } from 'react-router-dom'
import { setUserDetails } from '../../../Redux/actions'
import { connect } from 'react-redux'
import { FaEye } from "react-icons/fa";
import { FaRegEyeSlash } from "react-icons/fa";


function LoginPage({setUserDetailsRedux}) {
    const navigation = useNavigate()
    const [isLoginAs, setIsLoginAs] = useState('agent')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [showPsssword, setShowPassword] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()

        let data = {
            "email": email,
            "password": password,
            "role":isLoginAs
        }
        loginApi(data, navigation,setUserDetailsRedux)
    }


    return (
        <>
            <section className="login_form_wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-6 col-lg-7 col-12 px-0">
                            <div className="login_img">
                                <img src="/img/login-bg.png" alt="img" />
                            </div>
                        </div>

                        <div className="col-xl-6 col-lg-5 col-12 d-flex justify-content-center align-items-center ">
                            <div className="login_form">
                                <div className="form">

                                    <div className="login_form_heading">
                                        <h2 >Welcome</h2>
                                        <span>Sign in to continue</span>
                                    </div>

                                    <ul className="tab-group">
                                    <li className={`tab ${isLoginAs==='agent'? "active":""}`} onClick={()=>setIsLoginAs('agent')} >Agent</li>
                                        <li className={`tab ${isLoginAs==='customer'? "active":""} m-0`} onClick={()=>setIsLoginAs('customer')} >Customer</li>
                                    </ul>
                                    <div className="tab-content">
                                        <form onSubmit={(e)=>handleSubmit(e)} >
                                            <div className="mb-3 input-wrapper">
                                                <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
                                                <input type="email" className="form-control" value={email} onChange={(e)=>setEmail(e.target.value)} placeholder="Enter Your Email" />
                                            </div>
                                            <div className="mb-3 input-wrapper">
                                                <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                                                <div className='position-relative' >
                                                <input type={`${showPsssword?"text":"password"}`} className="form-control" value={password} onChange={(e)=>setPassword(e.target.value)} placeholder="Enter Your Password" />
                                                {
                                                    showPsssword 
                                                    ?
                                                    <FaRegEyeSlash className='show_password_icon' onClick={()=>setShowPassword(!showPsssword)} />
                                                    :
                                                    <FaEye className='show_password_icon' onClick={()=>setShowPassword(!showPsssword)}/>
                                                }
                                                </div>
                                            </div>
                                            <div className="mb-3 remember_wrapper form-check d-flex justify-content-between">
                                                <div></div>
                                                <div>
                                                    <a href="/forgot-password">Forgot Password ?</a>
                                                </div>
                                            </div>
                                            <div className="login_form_btn mt-4">
                                                <button type="submit" className="btn">Sign in</button>
                                            </div>
                                            <p>Don’t have account? <a href="/sign-up">Register Now</a></p>

                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


const mapStateToProps = (state) => {
    return {
    //   getStoreUserToken: state.storeUserToken.token,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        setUserDetailsRedux: (parameter) => {
        dispatch(setUserDetails(parameter));
      }
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);

  
  
