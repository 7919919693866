import { useState, useLayoutEffect } from 'react'
import HomeBanner from 'Customer/Layouts/HomeLayouts/HomeBanner'
import PackagePhotoGallery from 'Customer/Layouts/PackagesDetailsPageLayouts/PackagePhotoGallery'
import PackageMainDetails from 'Customer/Layouts/PackagesDetailsPageLayouts/PackageMainDetails'
import PackagesTabs from 'Customer/Layouts/PackagesDetailsPageLayouts/PackagesTabs'
import PackageOverview from 'Customer/Layouts/PackagesDetailsPageLayouts/PackageOverview'
import PackagesAmenities from 'Customer/Layouts/PackagesDetailsPageLayouts/PackagesAmenities'
import PackageLocation from 'Customer/Layouts/PackagesDetailsPageLayouts/PackageLocation'
import PackageGuestReview from 'Customer/Layouts/PackagesDetailsPageLayouts/PackageGuestReview'
import PackageCancellationPolicy from 'Customer/Layouts/PackagesDetailsPageLayouts/PackageCancellationPolicy'

import PackagePolicy from 'Customer/Layouts/PackagesDetailsPageLayouts/PackagePolicy'
import PackageSimiliar from 'Customer/Layouts/PackagesDetailsPageLayouts/PackageSimiliar'
import { getSingleOfferApi, getAllOffersApi } from 'Customer/Apis/MainApis/mainApis'

function PackagesDetailsOfferPage() {
  const [selectedTab, setSelectedTab] = useState('1')
  const [singlePackageData, setSinglePackageData] = useState(false)
  const [allOfferLists, setAllOffersList] = useState([])
  let packageID = window.location.pathname.split("/")[3];
  let offerId = window.location.pathname.split("/")[4];
  let offerDiscountPrice = window.location.pathname.split("/")[5];


  useLayoutEffect(() => {
    getSinglePackages()
    getAllOffers()
  }, [packageID])

  const getSinglePackages = async () => {
    getSingleOfferApi(offerId,offerDiscountPrice,packageID, (data) => {
      setSinglePackageData(data[0])
    })
  }
  const getAllOffers = async () => {
    getAllOffersApi(1, "Package", (data) => {
      setAllOffersList(data?.data)
    })
  }
  console.log(singlePackageData,'singlePackageDatasinglePackageDatasinglePackageDatasinglePackageData')
  return (
    <div>
      <HomeBanner />
      <PackagePhotoGallery singlePackageData={singlePackageData} allOfferLists={allOfferLists} />
      <PackageMainDetails singlePackageData={singlePackageData} />
      <PackagesTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      {
        selectedTab === '1'
          ?
          <PackageOverview singlePackageData={singlePackageData} />
          : null
      }
      {
        selectedTab === '2'
          ?
          <PackagesAmenities singlePackageData={singlePackageData} />
          : null
      }
      {
        selectedTab === '3'
          ?
          <PackageLocation singlePackageData={singlePackageData} />
          : null
      }
      {
        selectedTab === '4'
          ?
          <PackageGuestReview singlePackageData={singlePackageData} />
          : null
      }
      {
        selectedTab === '5'
          ?
          <PackageCancellationPolicy singlePackageData={singlePackageData} />
          : null
      }
      {
        selectedTab === '6'
          ?
          <PackagePolicy singlePackageData={singlePackageData} />
          : null
      }
      {
        selectedTab === '7'
          ?
          <PackageSimiliar />
          : null
      }


    </div>
  )
}

export default PackagesDetailsOfferPage
