import React from 'react'
import SideBar from 'Agent/components/SideBar'
import MainDashboard from 'Agent/components/MainDashboard'

function Dashboard() {
  return (
    <div style={{background:"#f0f0f0"}} >
      <div className="container-fluid">
         <div className="row">
             <div className="col-2">
                 <SideBar />
             </div>
             <div className="col-10">
                 <MainDashboard />
             </div>
         </div>
      </div>
    </div>
  )
}

export default Dashboard
