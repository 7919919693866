import React, { useEffect, useRef, useState } from 'react'
import gsap from 'gsap';

function Testing() {

    const [showAnimButton, setShowAnimButton] = useState(false)
    const containerRef = useRef(null);

    useEffect(() => {
        if(showAnimButton){
            const container = containerRef.current;
            const flowerCount = 50;
            for (let i = 0; i < flowerCount; i++) {
                const flower = document.createElement('div');
                flower.className = 'flower';
                flower.style.position = 'absolute';
                flower.style.left = `${Math.random() * 200}px`; // Random horizontal position
                flower.style.top = `${Math.random() * -250}px`; // Start from above the viewport
                flower.style.width = '8px';
                flower.style.height = '8px';
                flower.style.background = `url('/img/heart.png') no-repeat center center`;
                flower.style.backgroundSize = 'cover';
                container.appendChild(flower);
    
                // Animate flower falling
                gsap.to(flower, R(2, 16), {
                    rotationX: R(0, 360),
                    rotationY: R(0, 360),
                    rotationZ: R(0, 360),
                    duration: Math.random() * 15 + 15, // Random duration between 5s and 10s
                    y: '200px',
                    ease: 'linear',
                    repeat: -1, // Loop forever
                    delay: Math.random() * 5 // Random delay before starting the animation
                });
            }
            function R(min, max) {
                return min + Math.random() * (max - min);
              }
            // Clean up flower elements on component unmount
            return () => {
                const flowers = container.querySelectorAll('.flower');
                flowers.forEach(flower => flower.remove());
            };
        }
     
    }, [showAnimButton]);

    return (
        <div>
            <h2>Hello</h2>
            {
                showAnimButton
                    ?
                    <button onMouseLeave={() => setShowAnimButton(false)} ref={containerRef} style={{ position: 'relative', overflow: 'hidden', width: '200px', height: '50px', border: "1px solid gray", marginLeft: "20px", borderRadius: "10px", background: "#fff" }}>
                        Hover Here
                    </button>
                    :
                    <button ref={containerRef} onMouseEnter={() => setShowAnimButton(true)} onMouseLeave={() => setShowAnimButton(false)} className='button_anim' >Hover here</button>
            }



        </div>
    )
}

export default Testing
