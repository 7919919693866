import { USER_DETAILS} from "./type";


export const setUserDetails= (parameter)=>{
    return {
        type: USER_DETAILS,
        payload:parameter
    }  
}

// export const setUserCordinates= (parameter)=>{
//     return {
//         type: USER_CORDINATES,
//         payload:parameter
//     }  
// }

// export const setSelectedLanguages= (parameter)=>{
//     return {
//         type: SELECTED_LANGUAGE,
//         payload:parameter
//     }  
// }
// export const setAddress= (parameter)=>{
//     return {
//         type: ADDRESS,
//         payload:parameter
//     }  
// }
// export const setTimeSlot= (parameter)=>{
//     return {
//         type: TIME_SLOT,
//         payload:parameter
//     }  
// }

// export const setUpdatePageGlobal= (parameter)=>{
//     return {
//         type: UPDATE_PAGE,
//         payload:parameter
//     }  
// }
// export const setAllServiceAction= (parameter)=>{
//     return {
//         type: ALL_SERVICES,
//         payload:parameter
//     }  
// }