import React from 'react'
import 'Customer/Styles/MainStyles/packageDetails/packageguestreview.css'
import { BsCloudUpload } from "react-icons/bs";

function PackageGuestReview() {
  return (
    <div>
       <section className="package_reviews_sec_wrapper mb-5" id="reviews">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 px-0">
            <div className="package_review_wrapper">
           <div className="row">
            <div className="col-12">
              <div className="package_detail_tabs_heading d-flex justify-content-between">
                <h2>Guest Reviews</h2>
                <a href="#">See all reviews</a>
              </div>
            </div>

            <div className="col-12">
               <div className="rating_bar_wrapper">
                <div className="package_rating_content">
                  <h4>Rating</h4> 
                  <p>4.5/5</p>
                  <span>601 Ratings</span>
                  <span>206 Reviews</span>
                </div>
               </div>
            </div>
            <div className="col-12">
            <div className="package_review_sec_wrapper">
              <div className="review_sec">
                <div className="row">
                  <div className="col-12">
                    <div className="package_review_header">
                      <div className="review_sec_img">
                        <img src="/img/avtar.png" alt="img"/>
                      </div>
                      <div className="review_heading">
                         <div className="review_main_heading">
                            <h4>Random Person</h4>
                            <span>(Stayed 24 Nov, 2023)</span>
                         </div>
                         <div className="review_sub_heading">
                            <span>Family Traveller | 1 Review Written</span>
                         </div>
                      </div>
                    </div>
                  </div>
     
                   <div className="col-12">
                    <div className="review_content">
                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                        been the industry's standard dummy text ever since the 1500s, when an unknown printer took
                        a galley of type and scrambled it to make a type specimen book. It has survived not only five 
                        centuries, but also the leap into electronic typesetting.
                      </p>
                    </div>
                   </div>
     
                   <div className="col-12">
                      <div className="package_review_content_img_wrapper">
                       <div className="package_review_content_img">
                         <img src="/img/detail_resort-1.png" alt=""/>
                       </div>
     
                       <div className="package_review_content_img">
                         <img src="/img/detail_resort-1.png" alt=""/>
                       </div>
     
                       <div className="package_review_content_img">
                         <img src="/img/detail_resort-1.png" alt=""/>
                       </div>
     
                       <div className="package_review_content_img">
                         <img src="/img/detail_resort-1.png" alt=""/>
                       </div>
                        
                      </div>
                  </div>
                </div>
                </div>

                <div className="review_sec">
                  <div className="row">
                    <div className="col-12">
                      <div className="package_review_header">
                        <div className="review_sec_img">
                          <img src="/img/avtar.png" alt="img"/>
                        </div>
                        <div className="review_heading">
                           <div className="review_main_heading">
                              <h4>Random Person</h4>
                              <span>(Stayed 24 Nov, 2023)</span>
                           </div>
                           <div className="review_sub_heading">
                              <span>Family Traveller | 1 Review Written</span>
                           </div>
                        </div>
                      </div>
                    </div>
       
                     <div className="col-12">
                      <div className="review_content">
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                          been the industry's standard dummy text ever since the 1500s, when an unknown printer took
                          a galley of type and scrambled it to make a type specimen book. It has survived not only five 
                          centuries, but also the leap into electronic typesetting.
                        </p>
                      </div>
                     </div>
       
                     <div className="col-12">
                        <div className="package_review_content_img_wrapper">
                         <div className="package_review_content_img">
                           <img src="/img/detail_resort-1.png" alt=""/>
                         </div>
       
                         <div className="package_review_content_img">
                           <img src="/img/detail_resort-1.png" alt=""/>
                         </div>
       
                         <div className="package_review_content_img">
                           <img src="/img/detail_resort-1.png" alt=""/>
                         </div>
       
                         <div className="package_review_content_img">
                           <img src="/img/detail_resort-1.png" alt=""/>
                         </div>
                          
                        </div>
                    </div>
                  </div>
                  </div>

                  <div className="review_sec">
                    <div className="row">
                      <div className="col-12">
                        <div className="package_review_header">
                          <div className="review_sec_img">
                            <img src="/img/avtar.png" alt="img"/>
                          </div>
                          <div className="review_heading">
                             <div className="review_main_heading">
                                <h4>Random Person</h4>
                                <span>(Stayed 24 Nov, 2023)</span>
                             </div>
                             <div className="review_sub_heading">
                                <span>Family Traveller | 1 Review Written</span>
                             </div>
                          </div>
                        </div>
                      </div>
         
                       <div className="col-12">
                        <div className="review_content">
                          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took
                            a galley of type and scrambled it to make a type specimen book. It has survived not only five 
                            centuries, but also the leap into electronic typesetting.
                          </p>
                        </div>
                       </div>
         
                       <div className="col-12">
                          <div className="package_review_content_img_wrapper">
                           <div className="package_review_content_img">
                             <img src="/img/detail_resort-1.png" alt=""/>
                           </div>
         
                           <div className="package_review_content_img">
                             <img src="/img/detail_resort-1.png" alt=""/>
                           </div>
         
                           <div className="package_review_content_img">
                             <img src="/img/detail_resort-1.png" alt=""/>
                           </div>
         
                           <div className="package_review_content_img">
                             <img src="/img/detail_resort-1.png" alt=""/>
                           </div>
                            
                          </div>
                      </div>
                    </div>
                    </div>
            </div>
            </div>

            <div className="col-12">
              <div className="package_detail_tabs_heading d-flex justify-content-between align-items-baseline py-3">
                <h2>Write your Reviews</h2>
                <div className="review_rating_star d-flex align-items-center">
                  <h5 className='m-0' >Ratings</h5>
                  <ul className="d-flex m-0 p-0">
                    <li><img src="/img/colored-star.png" alt="star_img"/></li>
                    <li><img src="/img/colored-star.png" alt="star_img"/></li>
                    <li><img src="/img/colored-star.png" alt="star_img"/></li>
                    <li><img src="/img/colored-star.png" alt="star_img"/></li>
                    <li><img src="/img/simple-star.png"  alt="star_img"/></li>
                  </ul>
                </div>
              </div> 

              <div className="review_writting_sec">
                <div className="form-floating">
                  <textarea className="form-control" placeholder="Leave a comment here"></textarea>
                  <label htmlFor="floatingTextarea2">Would you like to write anything about this Holidays</label>
                </div>
                <div className="review_writting_btn d-flex my-4 align-items-center justify-content-between">
                <div className="d-flex maldives_resort_details_review_upload_file_button">
                  <ul className="d-flex m-0 p-0">
                    <li>
                      <label htmlFor="f">
                        <BsCloudUpload/>
                      </label>
                      <input type="file" id="f"/>
                    </li>
                    <li>
                      <label htmlFor="f">
                        <BsCloudUpload/>
                      </label>
                      <input type="file" id="f"/>
                    </li>
                    <li>
                      <label htmlFor="f">
                        <BsCloudUpload/>
                      </label>
                      <input type="file" id="f"/>
                    </li>
                    <li>
                      <label htmlFor="f">
                        <BsCloudUpload/>
                      </label>
                      <input type="file" id="f"/>
                    </li>
                  </ul>
                </div>

                <div className="word_limits">
                  <p>2000 characters remaining</p>
                </div>
                </div>

                <button className="btn"><a href="#">Submit Review</a></button>
              </div>
            </div>

           
            </div>
             </div>
             
            </div>
          </div>
        </div>
     </section>
    </div>
  )
}

export default PackageGuestReview
