
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

function BookingSuccessModal({ bookingSuccessShow, setBookingSuccessShow }) {
    const navigation =useNavigate()
    return (
        <div>
            <Modal
            centered
                show={bookingSuccessShow}
                onHide={() => {
                    setBookingSuccessShow(false);
                }}
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <img className="w-100" src="/img/booking_success.gif" alt="image" />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setBookingSuccessShow(false);
                        }}
                    >
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            setBookingSuccessShow(false);
                            navigation("/");
                        }}
                    >
                        Go To Home
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default BookingSuccessModal
