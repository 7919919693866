import {createStore, combineReducers , applyMiddleware} from 'redux';
import {thunk} from 'redux-thunk';

import setUserDetailsReducer from './reducers/setUserDetailsReducer';


export const rootReducer =  combineReducers({
    setUserDetailsReducer:setUserDetailsReducer
});
  
// Passing burgerReducer to createStore
const store=createStore(rootReducer, applyMiddleware(thunk));
  
export default store;