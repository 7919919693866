import React,{useState, useEffect} from 'react'
import PackagesFilters from 'Customer/Layouts/PackagesListingLayouts/PackagesFilters'
import PackagesListingLayout from 'Customer/Layouts/PackagesListingLayouts/PackagesListingLayout'
import HomeBanner from 'Customer/Layouts/HomeLayouts/HomeBanner'
import { getAllListingOfferApi } from 'Customer/Apis/MainApis/mainApis'


function PackageOfferListing() {
  let offerDiscountPrice = window.location.pathname.split("/")[4];
  let offerId = window.location.pathname.split("/")[3];
  const [allPackageListing, setAllPackagesListing] = useState([])
  const [page, setPage] = useState(1)

  useEffect(()=>{
      getAllPackages()
  },[])

  const getAllPackages = async()=>{
    getAllListingOfferApi(page,offerId,offerDiscountPrice,(data)=>{
      setAllPackagesListing(data)
    })
  }


  return (
    <div>
      <HomeBanner />

      <section className="mx-5 px-5 mt-4">
        <div className="container-fluid">
          <div className="row">
            < div className="col-12">
              <div className="">
                <div className="row">
                  <div className="col-2">
                    <PackagesFilters />
                  </div>
                  <div className='col-10' >
                    <PackagesListingLayout allPackageListing={allPackageListing} offer={true} offerId={offerId} 
                    offerDiscountPrice={offerDiscountPrice} />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default PackageOfferListing;
