import React,{useState, useEffect} from 'react'
import 'Customer/Styles/MainStyles/packageDetails/similiarrepackage.css'
import { image_url } from 'Utils/BaseURL'
import { getAllPackagessApi } from 'Customer/Apis/MainApis/mainApis'


function PackageSimiliar() {
  const [allPackageListing, setAllPackagesListing] = useState([])
  const [page, setPage] = useState()

  useEffect(()=>{
      getAllPackages()
  },[])

  const getAllPackages = async()=>{
    getAllPackagessApi(page,(data)=>{
      setAllPackagesListing(data)
    })
  }

  return (
    <div>
      <section className="similar_property_wrapper" id="similar_properties">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 px-0">
              <div className="similar_property">
                <div className="row">
                  <div className="col-12">
                    <div className="detail_tabs_heading">
                      <h2>Similar Resorts</h2>
                    </div>
                  </div>
                </div>
                <div className="row">
                {
                    allPackageListing?.slice(0,4).map((item,i)=>{
                      return  <div key={i} className="col-3">
                      <div className="similar_property_cards">
                        <div className="card">
                          <img src={`${image_url}${item?.Images[0]?.src}`} className="card-img-top" alt="img" />
                          <div className="card-body">
                            <div className="card-head d-flex justify-content-between">
                              <h5 className="card-title">{item?.packageName}</h5>
                              <ul className="d-flex">
                                <li>4.0</li>
                                <li><img src="/img/colored-star.png" alt="image" /></li>
                                <li><img src="/img/colored-star.png" alt="image" /></li>
                                <li><img src="/img/colored-star.png" alt="image" /></li>
                                <li><img src="/img/colored-star.png" alt="image" /></li>
                                <li><img src="/img/simple-star.png" alt="image" /></li>
                              </ul>
                            </div>
                            <span className="sub-title">{item?.packageTitle}</span>
                            <p className="card-text">{item?.packageShortDescription}</p>
                            <div className="card_btn d-flex justify-content-between">
                              <a href="#" className="btn">View Details</a>
                              <a href="#" className="btn" style={{backgroundColor: "#11ABE7", color:" #ffffff", border: "none", marginLeft:"5px"}}>Book Now</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default PackageSimiliar
