import React, { useState, useEffect } from 'react'
import PackagesFilters from 'Customer/Layouts/PackagesListingLayouts/PackagesFilters'
import PackagesListingLayout from 'Customer/Layouts/PackagesListingLayouts/PackagesListingLayout'
import HomeBanner from 'Customer/Layouts/HomeLayouts/HomeBanner'
import { getAllPackagessApi } from 'Customer/Apis/MainApis/mainApis'
function PackageListing() {
  const [allPackageListing, setAllPackagesListing] = useState([])
  const [page, setPage] = useState(1)

  useEffect(() => {
    getAllPackages()
  }, [])

  const getAllPackages = async () => {
    getAllPackagessApi(page, (data) => {
      setAllPackagesListing(data)
    })
  }


  return (
    <div>
      <HomeBanner />

      <section className="mx-5 px-5 mt-4">
        <div className="container-fluid">
          <div className="row">
            < div className="col-12">
              <div className="">
                <div className="row">
                  <div className="col-2">
                    <PackagesFilters />
                  </div>
                  <div className='col-10' >
                    <PackagesListingLayout allPackageListing={allPackageListing} offer={false}
                      offerId={false}
                      offerDiscountPrice={false}
                    />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default PackageListing
