import { useState } from 'react'
import 'Customer/Styles/MainStyles/resortListingFilter.css'
import { FiSearch } from "react-icons/fi";
import MultiSelect from 'react-multiple-select-dropdown-lite'
import 'react-multiple-select-dropdown-lite/dist/index.css'
import { Form } from "react-bootstrap";

function ResortFilters() {
    const [selectPrice, setSelectedPrice] = useState([])
    const [selectedLocation, setSelectedLocation] = useState([]);
    const [selectedPropertyType, setSelectedPropertyType]= useState([])
    const options = [
        { label: "Grape", value: "grapes" },
        { label: "Mango", value: "mango" },
        { label: "Strawberry ", value: "strawberry" },
    ];

    const propertyTypeOptions = [
        { label: "Villa", value: "Villa" },
        { label: "Resort", value: "Resort" },
        { label: "Hotel ", value: "Hotel" },
    ];

    const priceOptions = [
        { label: "10K", value: "10K" },
        { label: "20K", value: "20K" },
        { label: "30K ", value: "30K" },
    ];

    console.log(selectedLocation, 'selectedLocation')


    return (
        <>
            <div className="col-12 maldives_filters_resort_main">

                <div className="content_sidebar">
                    <div className="row">
                        <div className="col-12">
                            <div className="maldives_resort_filters_heading d-flex justify-content-between align-items-center">
                                <h4 className='m-0 p-0' >FILTERS</h4>
                                <span>CLEAR</span>
                            </div>

                            <hr className="my-3" />
                            <div className="">
                                <h5>Location</h5>
                                <div className="maldives_resort_filters_location_search_input ">
                                    <MultiSelect
                                        customValue={true}
                                        onChange={(val) => setSelectedLocation(val)}
                                        options={options}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                            </div>
                            <hr className="my-3" />

                            <div className="">
                                <h5>Price</h5>
                                <div className=" ">
                                    <MultiSelect
                                        placeholder="Min Price"
                                        singleSelect={true}
                                        customValue={true}
                                        onChange={(val) => setSelectedPrice(val)}
                                        options={priceOptions}
                                        style={{ width: "100%" }}
                                    />
                                    <h6 className='text-center my-2' >To</h6>
                                    <MultiSelect
                                        placeholder="Max Price"
                                        singleSelect={true}
                                        customValue={true}
                                        onChange={(val) => setSelectedPrice(val)}
                                        options={priceOptions}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                            </div>
                            <hr className="my-3" />
                            <div className="">
                                <h5>Ratings</h5>
                                <div className="maldives_resort_filters_location_search_input ">
                                    <Form>
                                        <Form.Check className='d-flex align-items-center' type="checkbox">
                                            <Form.Check.Input className='m-0' type="checkbox" isValid />
                                            <Form.Label className='d-flex align-items-center mt-2 mx-3' >
                                                <span>5.0</span>
                                                <img src="/img/colored-star.png" alt="" />
                                                <img src="/img/colored-star.png" alt="" />
                                                <img src="/img/colored-star.png" alt="" />
                                                <img src="/img/colored-star.png" alt="" />
                                                <img src="/img/colored-star.png" alt="" />
                                                <span>(50)</span>
                                            </Form.Label>
                                        </Form.Check>
                                        <Form.Check className='d-flex align-items-center' type="checkbox">
                                            <Form.Check.Input className='m-0' type="checkbox" isValid />
                                            <Form.Label className='d-flex align-items-center mt-2 mx-3' >
                                                <span>4.0</span>
                                                <img src="/img/colored-star.png" alt="" />
                                                <img src="/img/colored-star.png" alt="" />
                                                <img src="/img/colored-star.png" alt="" />
                                                <img src="/img/colored-star.png" alt="" />
                                                <img src="/img/simple-star.png" alt="" />
                                                <span>(56)</span>
                                            </Form.Label>
                                        </Form.Check>
                                        <Form.Check className='d-flex align-items-center' type="checkbox">
                                            <Form.Check.Input className='m-0' type="checkbox" isValid />
                                            <Form.Label className='d-flex align-items-center mt-2 mx-3' >
                                                <span>3.0</span>
                                                <img src="/img/colored-star.png" alt="" />
                                                <img src="/img/colored-star.png" alt="" />
                                                <img src="/img/colored-star.png" alt="" />
                                                <img src="/img/simple-star.png" alt="" />
                                                <img src="/img/simple-star.png" alt="" />
                                                <span>(13)</span>
                                            </Form.Label>
                                        </Form.Check>
                                        <Form.Check className='d-flex align-items-center' type="checkbox">
                                            <Form.Check.Input className='m-0' type="checkbox" isValid />
                                            <Form.Label className='d-flex align-items-center mt-2 mx-3' >
                                                <span>2.0</span>
                                                <img src="/img/colored-star.png" alt="" />
                                                <img src="/img/colored-star.png" alt="" />
                                                <img src="/img/colored-star.png" alt="" />
                                                <img src="/img/simple-star.png" alt="" />
                                                <img src="/img/simple-star.png" alt="" />
                                                <span>(980)</span>
                                            </Form.Label>
                                        </Form.Check>
                                        <Form.Check className='d-flex align-items-center' type="checkbox">
                                            <Form.Check.Input className='m-0' type="checkbox" isValid />
                                            <Form.Label className='d-flex align-items-center mt-2 mx-3' >
                                                <span>0</span>
                                                <img src="/img/simple-star.png" alt="" />
                                                <img src="/img/simple-star.png" alt="" />
                                                <img src="/img/simple-star.png" alt="" />
                                                <img src="/img/simple-star.png" alt="" />
                                                <img src="/img/simple-star.png" alt="" />
                                                <span>(980)</span>
                                            </Form.Label>
                                        </Form.Check>
                                    </Form>
                                </div>
                            </div>

                            <hr className="my-3" />
                            <div className="">
                                <h5>Property Type</h5>
                                <div className="maldives_resort_filters_location_search_input ">
                                    <MultiSelect
                                        customValue={true}
                                        onChange={(val) => setSelectedPropertyType(val)}
                                        options={propertyTypeOptions}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default ResortFilters
