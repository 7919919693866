import { baseURL } from "Utils/BaseURL";
import { SuccessToaster, ErrorToaster } from "Utils/Toast";
import axios from "axios";

export const loginApi = async (body, navigation, setUserDetailsRedux) => {
  let data = JSON.stringify(body);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}/login`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      if (response.data.status===false) {
        ErrorToaster(response.data.message);
      } else {
        if(response?.data?.role==="customer" && body?.role==="customer"){
            localStorage.setItem("maldive_auth_token", response.data.token)
            setUserDetailsRedux({user_data:response.data.data, user_token:response.data.token})
            SuccessToaster(response.data.message)
            navigation('/')
            /// redirect to user hom epage
        } else if(response?.data?.role==="customer" && body?.role==="agent"){
            ErrorToaster('This Email is registered as customer not for agent')
        } else if(response?.data?.role==="agent" && body?.role==="customer"){
                /// redirect to user hom epage
                localStorage.setItem("maldive_auth_token", response.data.token)
                setUserDetailsRedux({user_data:response.data.data, user_token:response.data.token})
                SuccessToaster(response.data.message)
                navigation('/')
        } else if(response?.data?.role==="agent" && body?.role==="agent"){
            /// redirect to agent dashboard epage
            ErrorToaster('We are working on agent panel')
    } 
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const signupApi = async (body, navigation) => {
  let data = JSON.stringify(body);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}/signup`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      if (response.data.status) {
        SuccessToaster(response.data.message);
        navigation('/login')
      } else {
        ErrorToaster(response.data.message);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const forgotPasswordApi = async (email, navigation)=>{
    let data = JSON.stringify({
        "email":email
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseURL}/forgot-password`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
  
    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        if (response.data.status) {
          SuccessToaster(response.data.message);
          navigation('/reset-password')
        } else {
          ErrorToaster(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
}

export const resetPasswordApi = async (body,userId, token, navigation)=>{
    let data = JSON.stringify(body);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseURL}/reset-password/${userId}/${token}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
  
    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        if (response.data.status) {
          SuccessToaster(response.data.message);
          navigation('/login')
        } else {
          ErrorToaster(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
}

export const getUserDetailsApi = async (token, callback)=>{
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${baseURL}/get-user-details`,
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`
    },
  };

  axios
    .request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      callback(response?.data?.data)
      // if (response?.data?.status === true || response?.data?.status) {
      //   callback(response?.data)
      // } else {
      //   ErrorToaster(response.data.message);
      // }
    })
    .catch((error) => {
      console.log(error);
    });
}