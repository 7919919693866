import React from 'react'
import 'Customer/Styles/MainStyles/blogsandarticles.css'
import { FaCalendarAlt } from "react-icons/fa";
import { FaRegUser } from "react-icons/fa6";


function BlogsAndArticles() {
    let data = [1, 2, 3]
    return (
        <>
            <section className="blogs_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="sec_heading">
                                <h2>Blogs & Articles</h2>
                            </div>
                        </div>

                        {
                            data?.map((item, i) => {
                                return <div key={i} className="col-md-4 col-sm-6 col-12 mt-3">
                                    <div className="card">
                                        <img src="/img/blog-1.png" className="card-img-top" alt="" />
                                        <div className="card-body">
                                            <div className="card-date d-flex justify-content-between">
                                                <span><FaCalendarAlt className='mx-2' />January 20 2021 </span>
                                                <span><FaRegUser />Lorem Ipsum</span>
                                            </div>
                                            <h5 className="card-title">Holidays: Lorem Ipsum is simply dummy text</h5>
                                            <p className="card-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry
                                                Lorem Ipsum the 1960s with the release of Letraset sheets scrambled.</p>
                                            <a href="#" className="btn">Read More</a>
                                        </div>
                                    </div>
                                </div>
                            })
                        }

                    </div>
                </div>
            </section>
        </>
    )
}

export default BlogsAndArticles
