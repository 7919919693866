import React from 'react'
import 'Customer/Styles/MainStyles/packageDetails/packageamenities.css'


function PackagesAmenities({singlePackageData}) {
  return (
    <div>
      <section className="package_amenities_wrapper" id="amenities">
        <div className="contanier-fluid">
          <div className="row">
            <div className="col-12">
              <div className="package_details_tabs_content_wrapper">
                <div className="detail_tabs_heading">
                  <h2>Amenities</h2>
                </div>
                <div className="details_tab_content d-flex">
                  <div className="post__content" dangerouslySetInnerHTML={{__html: singlePackageData?.amenitites}}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default PackagesAmenities

//////////// all folders is to be changed in package details page layouts 