
import './App.css';
import { useEffect } from 'react';
import Routes from 'Routes';
import { Toaster } from 'react-hot-toast';
import { getUserDetailsApi } from 'Customer/Apis/AuthApis/authApis';
import { setUserDetails } from './Redux/actions';
import { connect } from 'react-redux'

function App({setUserDetailsRedux}) {

  useEffect(() => {
    getUserDetails()
  }, [])

  const getUserDetails = async ()=>{
    let user_local_token = localStorage.getItem("maldive_auth_token")
    if(user_local_token){
      getUserDetailsApi(user_local_token, (data)=>{
        setUserDetailsRedux({user_data:data, user_token:user_local_token})
      })
    }
  }

  return (
    <>
    <Toaster />
    <Routes/>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    getUserDetailsRedux: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserDetailsRedux: (parameter) => {
      dispatch(setUserDetails(parameter));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);


