import { useState, useEffect, useLayoutEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import 'Customer/Styles/MainStyles/resortlistingmain.css'
import { FaPlus, FaMinus } from "react-icons/fa";
import { image_url } from 'Utils/BaseURL';

function ResortListingLayout({ allHotelsListing }) {
    const navigation = useNavigate()
    const [featureImage, setFeatureImage] = useState(false)

    const [isLogin, setIsLogin] = useState(false)
    const [person, setPerson] = useState(false)

    useLayoutEffect(() => {
        let user_local_token = localStorage.getItem("maldive_auth_token")
        setIsLogin(user_local_token)
    }, [])

    useEffect(() => {

    }, [])
    return (
        <div>
            <div className="col-12">
                <div className="inner_content_list">
                    {
                        allHotelsListing?.map((item, i) => {
                            return <div key={i} className="row">
                                <div className="col-12">
                                    <div className="maldives_main_resort_listing_card_box">
                                        <div className="row">
                                            <div className="col-4 d-flex align-items-center justify-content-center">
                                                <div className="list_img_gallery">
                                                    <div className="card-wrapper">
                                                        <div className="">
                                                            <div className="product-imgs">
                                                                <div className="img-display">
                                                                    <div className="img-showcase">
                                                                        {
                                                                            featureImage?.id === item?._id
                                                                                ?
                                                                                <img src={`${image_url}${featureImage?.src}`} alt="img" />
                                                                                :
                                                                                <img src={`${image_url}${item?.roomImage[0]?.src}`} alt="img" />
                                                                        }

                                                                    </div>
                                                                </div>
                                                                <div className="img-select">
                                                                    {
                                                                        item?.roomImage?.map((img, i) => {
                                                                            return <div key={i} className="img-item " onClick={() => {
                                                                                setFeatureImage({ id: item?._id, src: img?.src })
                                                                            }} >
                                                                                <img src={`${image_url}${img?.src}`} alt="img" />

                                                                            </div>
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-5">
                                                <div className="list_details_wrapper">
                                                    <div className="row">
                                                        <div className="col-12 d-flex justify-content-between">
                                                            <div className="list_ratings_wrapper d-flex">
                                                                <div className="list_rating d-flex align-items-center">
                                                                    <span>4.0</span>
                                                                    <img src="/img/colored-star.png" alt="rating" />
                                                                    <img src="/img/colored-star.png" alt="rating" />
                                                                    <img src="/img/colored-star.png" alt="rating" />
                                                                    <img src="/img/colored-star.png" alt="rating" />
                                                                    <img src="/img/simple-star.png" alt="rating" />
                                                                </div>
                                                            </div>

                                                            <div className="rating_verify">
                                                                <div>
                                                                    <span>4.5/5</span>
                                                                    (506 Verified Ratings)</div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12">
                                                            <div className="sec_heading">
                                                                <h4>{item?.hotelName}</h4>
                                                            </div>
                                                            <div className="list_content">
                                                                <p>
                                                                    {item?.hotelTitle}
                                                                </p>
                                                                <div className="list_location d-flex align-items-center">
                                                                    <div className="list_location_icon">
                                                                        <img src="/img/explore_nearby.png" alt="" />
                                                                    </div>
                                                                    <p>{item?.address}</p>
                                                                </div>

                                                                <div className="list_amenities_wrapper">
                                                                    <h4>Amenities</h4>
                                                                    <div className="list_amenities d-flex">
                                                                        <div className="post__content" dangerouslySetInnerHTML={{ __html: item?.amenitites }}></div>
                                                                    </div>
                                                                </div>

                                                                <div className="maldives_resort_listing_middle_section_buttons">
                                                                    <button className="cancellation">Cancellation</button>
                                                                    {/* <button className="add_on">Add On</button> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-3 d-flex align-items-center justify-content-center">
                                                <div className="maldives_main_resort_listing_card_box_list_last_section">
                                                    <div className="offer_wrapper">
                                                        <p>Free Cancellation </p>
                                                    </div>

                                                    <div className="maldives_main_resort_listing_card_box_list_last_section_off_wrapper">
                                                        <span>{item?.discountInPercentage}% Off</span>
                                                        <h4> <strike className="strike" >{item?.discountPrice} $</strike> {item?.minPrice} $</h4>
                                                        <p>+$300 Taxes & Fee</p>
                                                    </div>

                                                    {/* <div className='d-flex justify-content-between' >
                                                    <div className="maldives_main_resort_listing_card_box_list_last_section_adults">
                                                        <label htmlFor="exampleCheckIn" className="form-label">Adults</label>
                                                        <div className="d-flex justify-content-around align-items-center border p-1 rounded">
                                                            <FaMinus />
                                                            <span>{item?.price[0]?.person}</span>
                                                           <FaPlus onClick={()=>{
                                                            setPerson({id:item?._id, })
                                                           }} />
                                                        </div>
                                                    </div>
                                                    <div className="maldives_main_resort_listing_card_box_list_last_section_adults mx-2">
                                                        <label htmlFor="exampleCheckIn" className="form-label">Child</label>
                                                        <div className="d-flex justify-content-around align-items-center border p-1 rounded">
                                                            <FaMinus />
                                                            <span>{item?.price[0]?.person}</span>
                                                           <FaPlus />
                                                        </div>
                                                    </div> */}

                                                    {/* <div className="maldives_main_resort_listing_card_box_list_last_section_child">
                                                        <label htmlFor="exampleCheckIn" className="form-label">Childs</label>
                                                        <div className="number">
                                                            <span className="minus"><FaMinus /></span>
                                                            <span>1</span>
                                                            <span className="plus"><FaPlus /></span>
                                                        </div>
                                                    </div> */}
                                                    {/* </div> */}


                                                    <div className="list_last_btn d-flex flex-column">
                                                        {/* <button className="book_now_button">Book Now</button> */}
                                                        <button onClick={() => { navigation(`/customer/resort-details/${item?._id}`) }} className="view_details_button" >View Details</button>
                                                    </div>
                                                    {
                                                        isLogin
                                                            ?
                                                            null
                                                            :
                                                            <div className="list_login_btn">
                                                                <button className="btn">Login to Book</button>
                                                            </div>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default ResortListingLayout
