import React from 'react'
import 'Customer/Styles/MainStyles/clientTestimonial.css'
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

function ClientTestimonials() {
    return (
        <div>
            <section className="testimonial_wrapper container">
                <div className="testimonial_wrapper_bg_main">
                    <button className='testimonial_arrow_buttons' ><IoIosArrowBack /></button>
                    <div className="testimonial_wrapper_box">
                        <h2 className='text-white' >Client Testimonial</h2>
                        <h6 className='text-center text-white py-3' >“Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                            the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of
                            type and scrambled it to make a type specimen book.”</h6>
                        <div className="client_testimonial_users_details">
                            <img src="/img/dummy.png" alt="" />
                            <div className='mx-3' >
                                <h5 className='text-white' >Rahul</h5>
                                <h6 className='text-white'>CEO Cyberx</h6>
                            </div>
                        </div>
                    </div>
                    <button className='testimonial_arrow_buttons' ><IoIosArrowForward /></button>
                </div>
            </section>
        </div>
    )
}

export default ClientTestimonials
