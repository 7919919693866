import { useState } from "react";
import "Customer/Styles/MainStyles/allOffers.css";
import { image_url } from "Utils/BaseURL";
import { useNavigate } from "react-router-dom";

function AllOffers({
  allOffersList,
  selectedTab,
  setSelectedTab,
  handleChangeOfferType,
}) {
  const navigation = useNavigate();
  const handleViewOffers = async () => {};
  return (
    <>
      <section className="offers_wrappers">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section_offers_wrapper d-flex justify-content-lg-between flex-column flex-xl-row">
                <div className="sec_heading">
                  <h2>Offers for you</h2>
                </div>
                <div className="offer_tabs_sec">
                  <div className="allOffers_navtabs">
                    <div
                      onClick={() => {
                        setSelectedTab("");
                        handleChangeOfferType("");
                      }}
                      className={`allOffers_navtab ${
                        selectedTab == "" ? "allOffers_active" : ""
                      }`}
                    >
                      All
                    </div>
                    <div
                      onClick={() => {
                        setSelectedTab("Hotel");
                        handleChangeOfferType("Hotel");
                      }}
                      className={`allOffers_navtab ${
                        selectedTab == "Hotel" ? "allOffers_active" : ""
                      }`}
                    >
                      Hotels
                    </div>
                    <div
                      onClick={() => {
                        setSelectedTab("Package");
                        handleChangeOfferType("Package");
                      }}
                      className={`allOffers_navtab ${
                        selectedTab == "Package" ? "allOffers_active" : ""
                      }`}
                    >
                      Package
                    </div>
                    <div
                      onClick={() => {
                        setSelectedTab("Jungle Safari");
                        handleChangeOfferType("Jungle Safari");
                      }}
                      className={`allOffers_navtab ${
                        selectedTab == "Jungle Safari" ? "allOffers_active" : ""
                      }`}
                    >
                      Jungle Safari
                    </div>
                    {/* <div onClick={()=>setSelectedTab('speedboat')} className={`allOffers_navtab ${selectedTab=='speedboat'? "allOffers_active":''}`} >Speed Boat</div>
                                        <div onClick={()=>setSelectedTab('bundle')} className={`allOffers_navtab ${selectedTab=='bundle'? "allOffers_active":''}`} >Holiday Bundle</div> */}
                    <div className="underline"></div>
                  </div>
                </div>
              </div>

              <div id="all" className="content active">
                <div className="row">
                  {allOffersList?.length === 0 ? (
                    <div>
                      <h6>No Offers</h6>
                    </div>
                  ) : (
                    ""
                  )}
                  {allOffersList?.slice(0, 6)?.map((item, i) => {
                    return (
                      <div
                        key={i}
                        className="col-xl-4 col-lg-6 col-md-6 col-12"
                      >
                        <div className="offer_tabs_cards">
                          <div className="row">
                            <div className="col-4">
                              <div className="offers_tabs_card_img">
                                <img
                                  src={`${image_url}${item?.image}`}
                                  alt="image"
                                />
                              </div>
                            </div>
                            <div className="col-8">
                              <div className="offer_card_content">
                                <div className="offer_card_heading d-flex justify-content-between">
                                  <span>{item?.offerFor}</span>
                                  <span>T&C Apply</span>
                                </div>

                                <div className="offer_card_heading">
                                  <h4>{item?.offerName}</h4>
                                </div>
                                <div className="offer_card_content">
                                  <p>{item?.description} </p>
                                </div>
                                <div className="offer_card_content">
                                  <p>{item?.discountInPercentage}% Off </p>
                                </div>
                                {/* discountInPercentage */}
                                <div className="offer_card_btn">
                                  <button
                                    onClick={() => {
                                      if (item?.offerFor === "Package") {
                                        navigation(
                                          `/view-all-offers/packages/${item?._id}/${item?.discountInPercentage}`
                                        );
                                      } else if (item?.offerFor === "Hotel") {
                                        navigation(
                                          `/view-all-offers/hotels/${item?._id}/${item?.discountInPercentage}`
                                        );
                                      } else {
                                        navigation(
                                          `/view-all-offers/jungle-safari/${item?._id}/${item?.discountInPercentage}`
                                        );
                                      }
                                    }}
                                    className="btn"
                                  >
                                    Book
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {selectedTab == "" ? (
                  <div className="d-flex w-100 justify-content-end mt-4">
                    <p
                      className="view_all_offers"
                      onClick={() => {
                        navigation("/view-all-offers");
                      }}
                    >
                      View All
                    </p>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AllOffers;
