import React from 'react'
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;
function JungleSafariLocation({singlePackageData}) {
  const defaultProps = {
    center: {
      lat: singlePackageData?.lat,
      lng: singlePackageData?.lng
    },
    zoom: 11
  };

  return (
    <div>
      <div style={{ height: '50vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "" }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
        >
          <AnyReactComponent
            lat={singlePackageData?.lat}
            lng={singlePackageData?.lng}
            text="My Marker"
          />
        </GoogleMapReact>
      </div>
    </div>
  )
}

export default JungleSafariLocation
