import React, { useState, useEffect } from 'react'
import './../styles/sidebar.css'

function SideBar() {
    const [selectedRoute, setSelectedRoute] = useState('')
    let pathName = window.location.pathname.split("/")[2]
    return (
        <>
            <section className="dashboard_wrapper">
                <div className="side-bar_wrapper">
                    <div className="row mx-0">

                        <div className="col-12">
                            <div className="sidebar_logo">
                                <img src="/img/logo.png" alt="img" />
                            </div>
                        </div>
                        <hr />
                        <div className="col-12">
                            <div className="dashboard_tabs_wrapper">
                                <ul>
                                    <li className={`${pathName === "dashboard" ? "active_dashboard_tabs" : "dashboard_tabs"}`}>
                                        <a href="" className="d-flex align-items-center">
                                            <div className="dashboard_tab_img">
                                                <img src="/img/dashboard.png" alt="img" />
                                            </div>
                                            <div className="dashboard_tab_content">
                                                <h4>Dashboard</h4>
                                            </div>
                                        </a>
                                    </li>

                                    <li className={`${pathName === "edit-profile" ? "active_dashboard_tabs" : "dashboard_tabs"}`}>
                                        <a href="/agent/edit-profile" className="d-flex align-items-center">
                                            <div className="dashboard_tab_img">
                                                <img src="/img/edit_profile.png" alt="img" />
                                            </div>

                                            <div className="dashboard_tab_content">
                                                <h4>Edit Profile</h4>
                                            </div>
                                        </a>
                                    </li>

                                    <li className={`${pathName === "change-password" ? "active_dashboard_tabs" : "dashboard_tabs"}`}>
                                        <a href="/agent/change-password" className="d-flex align-items-center">
                                            <div className="dashboard_tab_img">
                                                <img src="/img/pswd.png" alt="img" />
                                            </div>

                                            <div className="dashboard_tab_content">
                                                <h4>Change Password</h4>
                                            </div>
                                        </a>
                                    </li>

                                    <li className={`${pathName === "my-bookings" ? "active_dashboard_tabs" : "dashboard_tabs"}`}>
                                        <a href="/agent/my-bookings" className="d-flex align-items-center">
                                            <div className="dashboard_tab_img">
                                                <img src="/img/booking.png" alt="img" />
                                            </div>
                                            <div className="dashboard_tab_content">
                                                <h4>My Bookings</h4>
                                            </div>
                                        </a>
                                    </li>

                                    <li className={`${pathName === "clients" ? "active_dashboard_tabs" : "dashboard_tabs"}`}>
                                        <a href="#" className="d-flex align-items-center">
                                            <div className="dashboard_tab_img">
                                                <img src="/img/clients.png" alt="img" />
                                            </div>
                                            <div className="dashboard_tab_content">
                                                <h4>Clients</h4>
                                            </div>
                                        </a>
                                    </li>

                                    <li className={`${pathName === "all-tansactions" ? "active_dashboard_tabs" : "dashboard_tabs"}`}>
                                        <a href="/agent/all-tansactions" className="d-flex align-items-center">
                                            <div className="dashboard_tab_img">
                                                <img src="/img/transaction.png" alt="img" />
                                            </div>

                                            <div className="dashboard_tab_content">
                                                <h4>Transactions</h4>
                                            </div>
                                        </a>

                                    </li>
                                </ul>

                                <div className="dashboard_btn">
                                    <button className="btn">
                                        <a href="">
                                            <img src="/img/dashboard_btn.png" alt="" />Sign Out</a>
                                    </button>
                                </div>

                            </div>





                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default SideBar
