import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "Customer/Styles/MainStyles/packageDetails/packageaddonmodal.css";

function AllCouponModal({
  openAllCouponModal,
  setOpenAllCouponModal,
  allCoupons,
  selectedCouponId,
  handleSelectCoupon
}) {

  return (
    <div>
      <Modal
        show={openAllCouponModal}
        onHide={() => {
          setOpenAllCouponModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Choose Coupon</Modal.Title>
        </Modal.Header>
        <Modal.Body>

            {allCoupons?.map((item, i) => (
              <div
                key={i}
                className="mb-0 border p-3 d-flex justify-content-between align-items-center" >
                <div>
                  <h4 >{item?.couponName}</h4>
                  <p className="m-0 p-0">{item?.description}</p>
                  <p className="m-0 p-0" style={{ color: "#ff6600" }}>
                    {item?.discountPrice} $
                  </p>
                </div>
                {
                 selectedCouponId ===item?._id
                  ?
                  <button className={`${"selected_button_package_Add_on"}`}
                  onClick={() => {
                    handleSelectCoupon(item?._id, item?.discountPrice)
                 
                 }}
                  >Selected</button>
                  :
                  <button className={`${"select_button_package_Add_on"}`}
                  onClick={() => {
                    handleSelectCoupon(item?._id, item?.discountPrice)
          
                 }}
                  >Select</button>
                }
               
              </div>
            ))}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setOpenAllCouponModal(false);
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setOpenAllCouponModal(false);
            }}
          >
            Select & Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AllCouponModal;
