import { baseURL } from "Utils/BaseURL";
import { SuccessToaster, ErrorToaster } from "Utils/Toast";
import axios from "axios";

//////// service
export const getAllServicesApi = async (page, callback) => {
  let data = JSON.stringify({
    page: page,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}/get-all-services`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      // console.log(JSON.stringify(response.data));
      callback(response?.data?.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getAllSubServicesApi = async (page, callback) => {
  let data = JSON.stringify({
    page: 1,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}/user/get-all-sub-services`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      callback(response.data.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

/////// hotels apis
export const getAllHotelsApi = async (page, callback) => {
  let data = JSON.stringify({
    page: page,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}/user/get-all-hotels`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      callback(response.data.data);
    })
    .catch((error) => {
      console.log(error);
    });
};
export const getSingleHotelApi = async (hotelId, callback) => {
  let data = JSON.stringify({
    hotelId: hotelId,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}/user/get-single-hotel`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      callback(response?.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getHotelsSearchHomeApi = async (body, callback) => {
  let data = JSON.stringify(body);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}/user/search-hotel'`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      callback(response?.data?.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

//////// packages apis
export const getAllPackagessApi = async (page, callback) => {
  let data = JSON.stringify({
    page: page,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}/user/get-all-packages`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      callback(response.data.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getSingleHPackageApi = async (packageId, callback) => {
  let data = JSON.stringify({
    packageId: packageId,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}/user/get-single-package`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      callback(response.data.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

//////// jungle safari apis
export const getAlljungleSafariApi = async (page, callback) => {
  let data = JSON.stringify({
    page: page,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}/user/get-all-jungle-safari`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      callback(response.data.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getSingleJungleSafariApi = async (jungleSafariId, callback) => {
  let data = JSON.stringify({
    jungleSafariId: jungleSafariId,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}/user/get-single-jungle-safari`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      callback(response.data.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

/////// offers
export const getAllOffersApi = async (page, type, callback) => {
  let data = JSON.stringify({
    page: page,
    type: type,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}/user/get-all-offers`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      // console.log(JSON.stringify(response?.data));
      callback(response?.data);
    })
    .catch((error) => {
      console.log(error);
    });
};
export const getAllListingOfferApi = async (
  page,
  offerId,
  offerDiscountPrice,
  callback
) => {
  let data = JSON.stringify({
    page: page,
    offerId: offerId,
    offerPrice: parseInt(offerDiscountPrice),
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}/user/get-offers-list`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      callback(response.data.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getSingleOfferApi = async (
  offerId,
  offerDiscountPrice,
  id,
  callback
) => {
  let data = JSON.stringify({
    offerId: offerId,
    offerPrice: parseInt(offerDiscountPrice),
    id: id,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}/user/get-single-offers-list`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      callback(response.data.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

//// add ons services

export const getAllAddOnServicesForPackage = async (body, callback) => {
  let data = JSON.stringify({
    allAddOns: body,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}/user/get-add-on-services-package`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      callback(response?.data?.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

////// coupon

export const getAllCoupons = async (body, callback) => {
  let data = JSON.stringify({
    allCoupons: body,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}/user/get-all-coupons`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      callback(response?.data?.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

//// home page segment ////

export const getAllSegmentHomeApi = async (page, subServiceId, callback) => {
  let data = JSON.stringify({
    page: page,
    subServiceId: subServiceId,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}/user/get-segments-list`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      callback(response?.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

//// booking api

export const hotelBookingApi = async (body, token, callback) => {
  let data = JSON.stringify(body);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}/user/user-hotel-booking`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      // console.log(JSON.stringify(response.data));
      if (response?.data?.status === true ||response?.data?.status ) {;
        SuccessToaster(response?.data?.message);
        callback(true)
      } else {
        ErrorToaster(response?.data?.message);
        callback(false)
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const checkRoomAvailablityApi = async(body, callback)=>{
  let data = JSON.stringify(body);
  
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${baseURL}/user/check-room-availability`,
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };
  
  axios.request(config)
  .then((response) => {
    console.log(JSON.stringify(response.data));
    if(response?.data?.status===true || response?.data?.status){
        SuccessToaster(response?.data?.message)
        callback(true)
    } else{
      ErrorToaster(response?.data?.message)
      callback(false)
    }
  })
  .catch((error) => {
    console.log(error);
  });
}