import React, { useState, useLayoutEffect, useRef , useEffect } from 'react'
import 'Customer/Styles/MainStyles/HomeBanner.css'
import { connect } from 'react-redux'
import { FaRegUser } from "react-icons/fa";
import { IoMdLogOut } from "react-icons/io";
import { IoBagCheckOutline } from "react-icons/io5";
import { FiUser } from "react-icons/fi";


function HomeBanner({ getUserDetailsRedux }) {
    const [isLogin, setIsLogin] = useState(false)
    const [showProfileDropdown, setShowProfileDropdown] = useState(false)

    useLayoutEffect(() => {
        let user_local_token = localStorage.getItem("maldive_auth_token")
        setIsLogin(user_local_token)
    }, [])

    const handleLogout = () => {
        localStorage.removeItem("maldive_auth_token")
        setIsLogin(false)
        setShowProfileDropdown(false);
    }

    const closeProfileDropdown = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (closeProfileDropdown.current && !closeProfileDropdown.current.contains(event.target)) {
        setShowProfileDropdown(false);
      }
    };

    if (showProfileDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showProfileDropdown]);

    return (
        <>
            <section className="hero_section_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="navbar">
                                <div className="nav-logo">
                                    <a href="/"><img src="/img/logo.png" alt="" /></a>
                                </div>
                                <div className="nav-items ms-auto">
                                    <ul className='m-0 p-0' >
                                        <li><a href="/"> Home </a></li>
                                        <li><a href="/customer/hotels-listing"> Holiday Resorts </a></li>
                                        <li><a href="/customer/packages-listing"> Holiday Packages </a></li>
                                        <li><a href="/customer/jungle-safari-listing"> Jungle Safari </a></li>
                                    </ul>
                                </div>
                                <div className='position-relative' ref={closeProfileDropdown}>
                                    <div className="nav-button " >
                                        {/* <div className="anim-layer"></div> */}
                                        {isLogin
                                            ?
                                            <div className='d-flex align-items-center px-3' onClick={() => setShowProfileDropdown(!showProfileDropdown)} >
                                                <button className='logout_button' >{getUserDetailsRedux?.user_data?.fullName}</button>
                                                <img width={30} height={30} src={getUserDetailsRedux?.user_data?.profileImg} alt="" />
                                            </div>
                                            :
                                            <a href="/login">Agent / Customer</a>
                                        }
                                    </div>
                                    {
                                        showProfileDropdown
                                            ?
                                            <div className='home-nav-profile-dropdown' >
                                                <p className='d-flex align-items-center' ><FiUser className='mx-2' />Profile</p>
                                                <p className='d-flex align-items-center'><IoBagCheckOutline className='mx-2' /> My Orders</p>
                                                <p className='d-flex align-items-center' onClick={handleLogout}><IoMdLogOut className='mx-2' /> Logout</p>
                                            </div>
                                            :
                                            null
                                    }

                                </div>
                                <div id="hamburger-menu">&#9776;</div>
                            </div>
                            <div id="mobile-menu">
                                <div className="mobile-nav-items">
                                    <ul>
                                        <li><a href="index.html"> Home </a></li>
                                        <li><a href="resorts.html"> Holiday Resorts </a></li>
                                        <li><a href="holiday-package.html"> Holiday Packages </a></li>
                                        <li><a href="speed-boat.html"> Speed Boat </a></li>
                                    </ul>
                                </div>
                                <div className="mobile-nav-button">
                                    <div className="anim-layer"></div>
                                    {isLogin
                                        ?
                                        <a href="/">Logout</a>
                                        :
                                        <a href="/">Login/Registration</a>
                                    }
                                </div>
                                <div id="hamburger-cross"><i className="fa-solid fa-xmark"></i>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 d-flex justify-content-center">
                            <div className="hero_sec_content">
                                <h1>Welcome to Maldives Holidays</h1>
                                <p>One stop solution for your next best holiday! Contact us to get
                                    the best offers.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


const mapStateToProps = (state) => {
    return {
        getUserDetailsRedux: state.setUserDetailsReducer
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeBanner);


