import React from 'react'
import './../styles/editprofile.css'

function ChangePasswordMain() {
    return (
        <div>
            <div className="dashbord_content_wrapper">
                <div className="row">
                    <div className="col-12">
                        <div className="dashboard_main-upper_wrapper d-flex justify-content-between align-items-center">
                            <h1>Dashboard</h1>
                            <div className="profile_sec_wrapper d-flex align-items-center">
                                <div className="notification me-4">
                                    <a href="#"> <img src="/img/notification.png" alt="" /></a>
                                </div>

                                <div className="profile_wrapper d-flex align-items-center">
                                    <div className="dashboard_profile_img">
                                        <img src="/img/dummy.png" alt="" />
                                    </div>
                                    <div className="dashboard_profile_name">
                                        Mr. Mehta
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="dashboard_content">
                            <div className="edit_profile_form">
                                <form>
                                    <div className="row">
                                        <div className="col">
                                            <label htmlFor="currentPassword" className="form-label">Current Password</label>
                                            <input type="text" className="form-control" id="currentPassword" />
                                        </div>
                                        <div className="col">
                                            <label htmlFor="newPassword" className="form-label">New Password</label>
                                            <input type="text" className="form-control" id="newPassword" />
                                        </div>
                                        <div className="col">
                                            <label htmlFor="cNewPassword" className="form-label">Confirm New Password</label>
                                            <input type="email" className="form-control" id="cNewPassword" />
                                        </div>
                                    </div>

                                    <div className="row mt-4 mx-1 d-flex">
                                        <button className="save_button">Change</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangePasswordMain
