import { USER_DETAILS } from "../type";

//initializing state
const initialState = {
  user_data: null,
  user_token: null,
};

const setUserDetailsReducer = (state = initialState, action) => {
  console.log(action,'actionactionactionactionactionaction')
  switch (action.type) {
    case USER_DETAILS:
      return {
        ...state,
        user_data: action.payload.user_data,
        user_token: action.payload.user_token,
      };
    default:
      return state;
  }
};

export default setUserDetailsReducer;
