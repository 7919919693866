import React from 'react'
import GoogleMapReact from 'google-map-react';

const GoogleMapIconMarker = ({ text }) => (<></>);


function ResortLocation({ singleHotelData }) {
  const defaultProps = {
    center: {
      lat: singleHotelData?.lat,
      lng: singleHotelData?.lng
    },
    zoom: 15
  };

  const renderMarkers = (map, maps) => {
    let marker = new maps.Marker({
      position: { lat: singleHotelData?.lat, lng: singleHotelData?.lng, },
      map,
      title: "Change Location",
      draggable: true,
    });

    maps.event.addListener(marker, "dragend", function (marker) {
      var latLng = marker.latLng;
      const reverseGeocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng.lat()},${latLng.lng()}&key=AIzaSyAKfvrGBxXsxJ2AovOGAdltyorLy4ytT1I`;

      fetch(reverseGeocodingUrl)
        .then((response) => response.json())
        .then((result) => {
          if (result) {
          } else {
            console.log("No address found");
          }
        });
    });
  };

  return (
    <div>
      <div style={{ height: '50vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyAKfvrGBxXsxJ2AovOGAdltyorLy4ytT1I" }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
        >
          <GoogleMapIconMarker
            lat={singleHotelData?.lat}
            lng={singleHotelData?.lng}
            text="My Marker"
          />
        </GoogleMapReact>
      </div>
    </div>
  )
}

export default ResortLocation
